import classnames from "classnames";
import { forwardRef, useEffect, useImperativeHandle, useRef } from "react";
import { NavLink } from "react-router-dom";
import constants from "./constants";
import { formatDecimals, RequiredStar } from "./utils";
import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { checkValidity } from "./checkValidity";
import { useDispatch, useSelector } from "react-redux";
import { getCriteriaValuesSubs } from "../store/actions";

export const InputText = function (props) {
  return (
    <input
      type="text"
      name={props?.name}
      value={props?.value}
      className="form-control"
      placeholder={props?.placeholder}
      onChange={props?.onChange}
    />
  );
};

export const InputText22nd = function (props) {
  const [valid, setValid] = useState(true);
  const [touched, setTouched] = useState(false);
  let inputClasses = "form-control";
  let errorClasses = "text-danger";
  if (!valid && props.shouldValidate && touched) {
    inputClasses = "form-control border-danger";
    errorClasses = "text-danger";
  }
  let actualValue = false;
  let removeChar;
  let removeDot;
  let formatedNumber;
  let theValue = props?.value;
  let msg = " Is Required";
  if (
    (props.value.trim() === "" || props?.value.length === 0) &&
    props.isClicked == true
  ) {
    actualValue = true;
  } else if (
    props?.inptype === "number" &&
    constants.containsOnlyNumbers(props?.value) !== true &&
    props.isClicked == true
  ) {
    actualValue = true;
    msg = "  igomba kuba imibare gusa";
  } else if (
    props?.inpvalue === "grant" &&
    props?.value > 100000000 &&
    props.isClicked == true
  ) {
    actualValue = true;
    msg = " Ntago igomba kurenga Frw 100,000,000";
  } else if (
    props?.inpvalue === "grant" &&
    props?.value > props?.totalinvestment &&
    props.isClicked == true
  ) {
    actualValue = true;
    msg = ` ${props?.value} Ntago igomba kuruta Total investment/Ingano y’igishoro ${props?.totalinvestment}`;
  }

  if (props?.inptype === "number") {
    removeChar = props?.value?.replace(/[^0-9.]/g, "");
    removeDot = removeChar.replace(/\./g, "");
    formatedNumber = removeDot.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    theValue = formatedNumber;
  }

  useEffect(() => {}, [valid, touched]);

  const onChangeHandler = (event) => {
    let value;
    if (event.target) {
      value = event.target.value;
    } else {
      value = event;
    }
    props.changed(value);
    if (props.shouldValidate) {
      try {
        setValid(checkValidity(value, props.validation));
        setTouched(true);
        //--
        if (props.isSubmitted === true && value === "") {
          setValid(checkValidity(value, { notEmpty: true }));
          setTouched(true);
        }
      } catch (err) {}
      //---
      try {
        props.load_modules(value);
      } catch (error) {}
      //--
      try {
        props.loadNewSubjectunits(value);
      } catch (error) {}

      try {
        props.getMultiselectedUnits(value);
      } catch (error) {}
    }
  };

  return (
    <div className="w-100">
      <span className="bdf-bluefontcolor">{props.title}</span>
      <RequiredStar />
      <input
        type="text"
        name={props.name}
        className={inputClasses}
        placeholder={props?.placeholder}
        onChange={onChangeHandler}
        value={props.value}
        onClick={props.inputClicked}
      />
      <span className={errorClasses}>
        {actualValue === true ? props.title + msg : ""}
      </span>
    </div>
  );
};

export const InputText2 = function (props) {
  const [valid, setValid] = useState(true);
  const [touched, setTouched] = useState(false);
  let actualValue = false;
  let removeChar;
  let removeDot;
  let formatedNumber;
  let theValue = props?.value;
  let actnumber = "";
  let gt = props?.greater;

  if (props?.inptype === "number") {
    removeChar = props?.value?.replace(/[^0-9.]/g, "");
    removeDot = removeChar.replace(/\./g, "");
    formatedNumber = removeDot.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    theValue = formatedNumber;
    actnumber = removeDot;
  }

  if (props?.totalinvestment && props?.inpvalue === "grant") {
    let remeChar = props?.totalinvestment?.replace(/[^0-9.]/g, "");
    gt = Number(remeChar.replace(/\./g, ""));
  }

  let msg = " Is Required";

  const onChangeHandler = (value) => {
    props.onChange(value);
    try {
      setTouched(true);
    } catch (error) {}
  };
  if (props?.isClicked === true && props?.value?.trim() === "") {
    actualValue = true;
  } else if (
    props?.inptype === "number" &&
    constants.containsOnlyNumbers(actnumber) !== true &&
    props.isClicked == true
  ) {
    actualValue = true;
    msg = "  must be only numbers";
  } else if (
    props?.inpvalue === "comparable" &&
    touched &&
    constants.equalToZero(actnumber) !== true
  ) {
    actualValue = true;
    msg = " can't be zero or negative";
  } else if (
    props?.inpvalue === "comparable" &&
    touched &&
    constants.lessAndGreater(gt, actnumber) === true
  ) {
    actualValue = true;
    msg = ` ${theValue} can't be greater to ${formatDecimals(props?.greater)}`;
  }

  return (
    <div className="w-100">
      <span className="bdf-bluefontcolor">{props.title}</span>
      <RequiredStar />
      <input
        type="text"
        name={props.name}
        value={theValue}
        className={classnames("form-control", {
          "form-control border-danger": actualValue,
        })}
        placeholder={props.placeholder}
        onChange={onChangeHandler}
        onClick={onChangeHandler}
      />
      <span className="text-danger">
        {actualValue === true ? props.title + msg : ""}
      </span>
    </div>
  );
};

export const InputText3 = function (props) {
  const [valid, setValid] = useState(true);
  const [touched, setTouched] = useState(false);
  let actualValue = false;
  let removeChar;
  let removeDot;
  let formatedNumber;
  let theValue = props?.value;
  let actnumber = "";
  let gt = props?.greater;

  if (props?.inptype === "number") {
    removeChar = props?.value?.replace(/[^0-9.]/g, "");
    removeDot = removeChar.replace(/\./g, "");
    formatedNumber = removeDot.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    theValue = formatedNumber;
    actnumber = removeDot;
  }

  if (props?.totalinvestment && props?.inpvalue === "grant") {
    let remeChar = props?.totalinvestment?.replace(/[^0-9.]/g, "");
    gt = Number(remeChar.replace(/\./g, ""));
  }

  let msg = " Is Required";

  const onChangeHandler = (value) => {
    props.onChange(value);
    try {
      setTouched(true);
    } catch (error) {}
  };
  if (props?.isClicked === true && props?.value?.trim() === "") {
    actualValue = true;
  } else if (
    props?.inptype === "number" &&
    constants.containsOnlyNumbers(actnumber) !== true &&
    props.isClicked == true
  ) {
    actualValue = true;
    msg = "  must be only numbers";
  } else if (
    props?.inpvalue === "comparable" &&
    touched &&
    constants.equalToZero(actnumber) !== true
  ) {
    actualValue = true;
    msg = " can't be zero or negative";
  } else if (
    props?.inpvalue === "comparable" &&
    touched &&
    constants.lessAndGreater(gt, actnumber) === true
  ) {
    actualValue = true;
    msg = ` ${theValue} can't be greater to ${formatDecimals(props?.greater)}`;
  }

  return (
    <div className="w-100 pt-1">
      <div className={"input-group"} >
      <span className="input-group-text" id="basic-addon3">{props.title}</span>
      <input
      value={theValue}
          name={props.name}
          type="text"
          className={classnames("form-control", {
            "form-control border-danger": actualValue,
          })}
          placeholder={props.placeholder}
        onChange={onChangeHandler}
        onClick={onChangeHandler}
          aria-describedby="basic-addon3" required />
  </div>
      <span className="text-danger">
        {actualValue === true ? props.title + msg : ""}
      </span>
    </div>
  );
};

export const InputTextSearch = function (props) {
  const [valid, setValid] = useState(true);
  const [touched, setTouched] = useState(false);
  let actualValue = false;
  let removeChar;
  let removeDot;
  let formatedNumber;
  let theValue = props?.value;
  let actnumber = "";
  let ti = props?.totalinvestment;

  if (props?.inptype === "number") {
    removeChar = props?.value?.replace(/[^0-9.]/g, "");
    removeDot = removeChar.replace(/\./g, "");
    formatedNumber = removeDot.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    theValue = formatedNumber;
    actnumber = removeDot;
  }

  if (props?.totalinvestment && props?.inpvalue === "grant") {
    let remeChar = props?.totalinvestment?.replace(/[^0-9.]/g, "");
    ti = Number(remeChar.replace(/\./g, ""));
  }

  let msg = " Is Required";

  const onChangeHandler = (value) => {
    props.onChange(value);
    try {
      setTouched(true);
    } catch (error) {}
  };

  if (props?.isClicked === true && props?.value?.trim() === "") {
    actualValue = true;
  } else if (
    props?.inptype === "number" &&
    constants.containsOnlyNumbers(actnumber) !== true &&
    props.isClicked == true
  ) {
    actualValue = true;
    msg = "  igomba kuba imibare gusa";
  } else if (
    props?.inpvalue === "grant" &&
    actnumber > 100000000 &&
    props.isClicked == true
  ) {
    actualValue = true;
    msg = " Ntago igomba kurenga Frw 100,000,000";
  } else if (
    props?.inpvalue === "grant" &&
    touched &&
    constants.lessAndGreater(ti, actnumber) !== true
  ) {
    actualValue = true;
    msg = ` ${theValue} Ntago igomba kuruta Total investment/Ingano y’igishoro ${formatDecimals(
      props?.totalinvestment
    )}`;
  }

  return (
    <div className="w-100">
      <span className="bdf-bluefontcolor">{props.title}</span>
      <input
        type="text"
        name={props.name}
        value={theValue}
        className={classnames("form-control", {
          "form-control border-danger": actualValue,
        })}
        placeholder={props.placeholder}
        onChange={onChangeHandler}
        onClick={onChangeHandler}
      />
      <span className="text-danger">
        {actualValue === true ? props.title + msg : ""}
      </span>
    </div>
  );
};

export const InputTextOC = function (props) {
  const [valid, setValid] = useState(true);
  const [touched, setTouched] = useState(false);
  let actualValue = false;
  let removeChar;
  let removeDot;
  let formatedNumber;
  let theValue = props?.value;
  let actnumber = "";
  let ti = props?.totalinvestment;
  if (props?.totalinvestment) {
    let remeChar = props?.value?.replace(/[^0-9.]/g, "");
    ti = remeChar.replace(/\./g, "");
  }

  if (props?.inptype === "number") {
    removeChar = props?.value?.replace(/[^0-9.]/g, "");
    removeDot = removeChar.replace(/\./g, "");
    formatedNumber = removeDot.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    theValue = formatedNumber;
    actnumber = removeDot;
  }

  let msg = " Is Required";
  if (props.value.trim() === "" && props.isClicked == true) {
    actualValue = true;
  } else if (
    props?.inptype === "number" &&
    constants.containsOnlyNumbers(actnumber) !== true &&
    props.isClicked == true
  ) {
    actualValue = true;
    msg = "  igomba kuba imibare gusa";
  } else if (
    props?.inpvalue === "grant" &&
    actnumber > 100000000 &&
    props.isClicked == true
  ) {
    actualValue = true;
    msg = " Ntago igomba kurenga Frw 100,000,000";
  } else if (
    props?.inpvalue === "grant" &&
    actnumber > ti &&
    props.isClicked === true
  ) {
    actualValue = true;
    msg = ` ${theValue} Ntago igomba kuruta Total investment/Ingano y’igishoro ${formatDecimals(
      props?.totalinvestment
    )}`;
  }

  return (
    <div className="w-100">
      <span className="bdf-bluefontcolor">{props.title}</span>
      <RequiredStar />
      <input
        type="text"
        name={props.name}
        value={theValue}
        className={classnames("form-control", {
          "form-control border-danger": actualValue,
        })}
        placeholder={props.placeholder}
        onChange={props.onChange}
      />
      <span className="text-danger">
        {actualValue === true ? props.title + msg : ""}
      </span>
    </div>
  );
};

export const InputNationalId = function (props) {
  let actualValue = false;
  let msg = " Is Required";
  let theValue = props?.value;
  let removeChar = props?.value?.replace(/[^0-9.]/g, "");
  let removeDot = removeChar.replace(/\./g, "");
  let formatedNumber = removeDot.replace(/\B((?!\d))/g, "");
  theValue = formatedNumber;
  let actnumber = removeDot;
  const dobanddate = () => {
    const ddob = props?.value?.substring(1, 5);
    const ddate = props?.dob?.getFullYear()?.toString();
    if (ddob === ddate) {
      return true;
    } else {
      return false;
    }
  };
  const genderanddate = () => {
    const dgender = props?.value?.substring(5, 6);
    const gender = props?.gender;
    if (dgender === "8" && gender === "male") {
      return true;
    } else if (dgender === "7" && gender === "female") {
      return true;
    } else {
      return false;
    }
  };
  if (
    props.value !== undefined &&
    props?.value?.trim() === "" &&
    props.isClicked == true
  ) {
    actualValue = true;
  } else if (
    props.value !== undefined &&
    (constants.containsOnlyNumbers(props?.value) !== true ||
      props.value.length !== 16) &&
    props.isClicked == true
  ) {
    actualValue = true;
    msg = " igomba kuba imibare 16 gusa";
  } else if (dobanddate() !== true && props.isClicked == true) {
    actualValue = true;
    msg = " " + " n'imyaka ntago bihuye";
  } else if (genderanddate() !== true && props.isClicked == true) {
    actualValue = true;
    msg = " " + " na gender (igitsina) ntago bihuye";
  } else if (props?.unique === "true" && props.isClicked == true) {
    actualValue = true;
    msg = " " + props.value + " yarakoreshejwe";
  }
  return (
    <div className="w-100">
      <span className="bdf-bluefontcolor">{props.title}</span>
      <RequiredStar />
      <input
        type="text"
        name={props.name}
        value={theValue}
        className={classnames("form-control", {
          "form-control border-danger": actualValue,
        })}
        placeholder={props.placeholder}
        onChange={props.onChange}
      />
      <span className="text-danger">
        {actualValue === true ? props.title + msg : ""}
      </span>
    </div>
  );
};

export const InputTIN = function (props) {
  let actualValue = false;
  let msg = " Is Required";
  if (props.value.trim() === "" && props.isClicked == true) {
    actualValue = true;
  } else if (
    props?.type === "tin" &&
    (constants.containsOnlyNumbers(props?.value) !== true ||
      props.value.length !== 9) &&
    props.isClicked == true
  ) {
    actualValue = true;
    msg = " igomba kuba imibare 9 gusa";
  } else if (
    props?.type === "rca" &&
    constants.validateRCANo(props?.value) !== true &&
    props.isClicked == true
  ) {
    actualValue = true;
    msg = ` igomba igomba iteye gutya RCA/xxxx/xxxx`;
  } else if (props?.unique === "true" && props.isClicked == true) {
    actualValue = true;
    msg = " " + props.value + " yarakoreshejwe";
  }
  return (
    <div className="w-100">
      <span className="bdf-bluefontcolor">{props.title}</span>
      <RequiredStar />
      <input
        type="text"
        name={props.name}
        value={props.value}
        className={classnames("form-control", {
          "form-control border-danger": actualValue,
        })}
        placeholder={props.placeholder}
        onChange={props.onChange}
      />
      <span className="text-danger">
        {actualValue === true ? props.title + msg : ""}
      </span>
    </div>
  );
};

export const InputPassport = function (props) {
  let actualValue = false;
  let msg = " Is Required";
  if (
    props.value !== undefined &&
    props.value.trim() === "" &&
    props.isClicked == true
  ) {
    actualValue = true;
  } else if (
    props.value !== undefined &&
    props.value.length < 6 &&
    props.isClicked == true
  ) {
    actualValue = true;
    msg = " igomba kuba inyuguti cyangwa imibare byibuze 6";
  } else if (props?.unique === "true" && props.isClicked == true) {
    actualValue = true;
    msg = " " + props.value + " yarakoreshejwe";
  }
  return (
    <div className="w-100">
      <span className="bdf-bluefontcolor">{props.title}</span>
      <RequiredStar />
      <input
        type="text"
        name={props.name}
        value={props.value}
        className={classnames("form-control", {
          "form-control border-danger": actualValue,
        })}
        placeholder={props.placeholder}
        onChange={props.onChange}
      />
      <span className="text-danger">
        {actualValue === true ? props.title + msg : ""}
      </span>
    </div>
  );
};

export const InputTelephone = function (props) {
  let actualValue = false;
  let theValue = props?.value;
  let msg = " Is Required";
  let removeChar = props?.value?.replace(/[^0-9.]/g, "");
  let removeDot = removeChar.replace(/\./g, "");
  let formatedNumber = removeDot.replace(/\B((?!\d))/g, "");
  theValue = formatedNumber;
  let actnumber = removeDot;

  if (props.value.trim() === "" && props.isClicked == true) {
    actualValue = true;
  } else if (
    (props?.value?.slice(0, 2) !== "07" ||
      constants.containsOnlyNumbers(props?.value) !== true ||
      props.value.length !== 10) &&
    props.isClicked == true
  ) {
    actualValue = true;
    msg = " igomba kuba imibare 10 gusa. format 07xxxxxxxx";
  } else if (props?.unique === "true" && props.isClicked == true) {
    actualValue = true;
    msg = " " + props.value + " yarakoreshejwe";
  }
  return (
    <div className="w-100">
      <span className="bdf-bluefontcolor">{props.title} (ex: 0788888888)</span>
      <RequiredStar />
      <input
        type="text"
        name={props.name}
        value={theValue}
        className={classnames("form-control", {
          "form-control border-danger": actualValue,
        })}
        placeholder={props.placeholder}
        onChange={props.onChange}
      />
      <span className="text-danger">
        {actualValue === true ? props.title + msg : ""}
      </span>
    </div>
  );
};

export const InputEmail = function (props) {
  const [valid, setValid] = useState(true);
  const [touched, setTouched] = useState(false);
  let actualValue = false;
  let msg = " Is Required";
  if (props.value.trim() === "" && props.isClicked == true) {
    actualValue = true;
  } else if (
    props?.value !== "" &&
    constants.validateEmail(props?.value) !== true &&
    props.isClicked == true
  ) {
    actualValue = true;
    msg = " igomba kuzuza ibisabwa";
  } else if (props?.unique === "true" && props.isClicked == true) {
    actualValue = true;
    msg = " " + props.value + " yarakoreshejwe";
  }
  return (
    <div className="w-100">
      <span className="bdf-bluefontcolor">{props.title}</span>
      <RequiredStar />
      <input
        type="text"
        name={props.name}
        value={props.value}
        className={classnames("form-control", {
          "form-control border-danger": actualValue,
        })}
        placeholder={props.placeholder}
        onChange={props.onChange}
      />
      <span className="text-danger">
        {actualValue === true ? props.title + msg : ""}
      </span>
    </div>
  );
};

export const InputTextSecret2 = function (props) {
  let actualValue = false;
  if (
    props.value.trim() === "" ||
    (props?.value.length === 0 && props.isClicked == true)
  ) {
    actualValue = true;
  }
  return (
    <div className="">
      <span className="bdf-bluefontcolor">{props.title}</span>
      <RequiredStar />
      <input
        type="password"
        name={props.name}
        value={props.value}
        className={classnames("form-control", {
          "form-control border-danger": actualValue,
        })}
        placeholder={props.placeholder}
        onChange={props.onChange}
      />
      <span className="text-danger">
        {actualValue === true ? props.title + " Is Required" : ""}
      </span>
    </div>
  );
};

export const SelectInput = function (props) {
  let actualValue = false;
  if (props.value == "" && props.isClicked == true) {
    actualValue = true;
  }
  const onChangeHandler = (event) => {
    let value;
    if (event.target) {
      value = event.target.value;
    } else {
      value = event;
    }
    props.changed(value);
  };
  return (
    <div className="">
      <span className="bdf-bluefontcolor">{props.title}</span>
      <RequiredStar />
      <select
        id={props?.id}
        className={classnames("w-full form-control", {
          "w-full form-control border-danger": actualValue,
        })}
        value={props.value}
        onChange={onChangeHandler}
      >
        <option>{props?.startingValue}</option>
        {props?.options.map((option, index) => (
          <option value={option.name || option.value} key={index}>
            {option.name || option.value}
          </option>
        ))}
      </select>
      <span className="text-danger">
        {actualValue === true ? props.title + " Is Required" : ""}
      </span>
    </div>
  );
};

export const SelectInputCriteria = (props) => {
  const dispatch = useDispatch();
  const criteriaValueSubs = useSelector(
    (state) => state.cdat.criteriaValueSubs
  );
  const [theTitle, setTheTitle] = useState("Select / Hitamo ");
  const [subs, setSubs] = useState([]);
  const [zero, setZero] = useState(0);
  let actualValue = false;
  if (props.value === "" && props.isClicked == true) {
    actualValue = true;
  }
  // console.log(`TRIALS`);
  // console.log(props?.subcriteriassi);
  // console.log(`TRIALS`);
  const onChangeHandler = (event) => {
    setZero(0);
    let value;
    if (event.target) {
      value = event.target.value;
    } else {
      value = event;
    }
    // dispatch(getCriteriaValuesSubs(props?.options[event.target.value].uuid));
    setTheTitle(props?.options[event.target.value].name);
    //  console.log("VALUE VALUE", props?.options[event.target.value])
    // console.log("VAL", value)
    // console.log("PARENT ID CRITERIA", props?.criteriaId);
    // console.log("PARENT MENU", props?.criteria);
    if (props?.haschildren === "true") {
      setSubs(props?.subcriteriass?.criteriaValues[value]?.subCriteriaValues);
    }
    props.changed(value, {
      criteria: props?.criteria,
      criteriaId: props?.criteriaId,
      criteriaValue: props?.options[event.target.value].name,
      criteriaValueId: props?.options[event.target.value].uuid,
      haschildren: props?.haschildren,
      //  subss: props?.subcriteriass?.criteriaValues,
    });
  };

  if (props?.haschildren === "true") {
  }

  useEffect(() => {}, [criteriaValueSubs]);

  const onChangeHandler2 = (event) => {
    let value = zero;
    if (event.target) {
      value = event.target.value;
    } else {
      value = event;
    }
    // console.log("WHY EPTY::", props?.subcriteriass?.criteriaValues[value]);
    // console.log("VALLA ID", props?.subcriteriass?.criteriaValues[value]?.uuid);
    // console.log("VALLA", props?.subcriteriass?.criteriaValues[value]?.name);
    console.log("PARENT ID CH", props?.criteriaId);
    console.log("PARENT MENU CH", props?.criteria);
    console.log("THIS VAL", value);

    console.log(
      "THIS VALUUU",
      props?.subcriteriass?.criteriaValues[value]?.subCriteriaValues[
        event.target.value
      ]?.name
    );
    console.log("THIS IIDD", props?.id);
    console.log(
      "THIS VALUUU",
      props?.subcriteriass?.criteriaValues[props?.id]?.subCriteriaValues[
        event.target.value
      ]?.name
    );
    console.log("IDDSS::", props?.subcriteriass?.criteriaValues[props?.id]);
    props.childchanged(value, {
      criteria: props?.subcriteriass?.criteriaValues[props?.id]?.name,
      criteriaId: props?.subcriteriass?.criteriaValues[props?.id]?.uuid,
      criteriaValue:
        props?.subcriteriass?.criteriaValues[props?.id]?.subCriteriaValues[
          event.target.value
        ]?.name,
      criteriaValueId:
        props?.subcriteriass?.criteriaValues[props?.id]?.subCriteriaValues[
          event.target.value
        ]?.uuid,
      parentId: props?.criteriaId,
      haschildren: props?.haschildren,
    });
  };
  //haschildren
  return (
    <div className="">
      <div className={props?.haschildren === "true" ? "row d-flex" : ""}>
        <div
          className={
            props?.haschildren === "true" ? "col-sm-10 col-md-6" : "col-md-11"
          }
        >
          <span className="bdf-bluefontcolor">{props.title}</span>
          <select
            id={props?.id}
            className={classnames("w-full form-control", {
              "w-full form-control border-danger": actualValue,
            })}
            value={props.value}
            onChange={onChangeHandler}
          >
            <option>{props?.startingValue}</option>
            {props?.options.map((option, index) => (
              <option value={index} key={index}>
                {option.name}
              </option>
            ))}
          </select>
          <span className="text-danger">
            {actualValue === true ? props.title + " Is Required" : ""}
          </span>
        </div>
        <div
          className={
            props?.haschildren === "true" ? "col-sm-10 col-md-6" : "d-none"
          }
        >
          <span className="bdf-bluefontcolor">{theTitle}</span>
          <select
            id={props?.id}
            className={classnames("w-full form-control", {
              "w-full form-control border-danger": actualValue,
            })}
            value={props.childvalue}
            onChange={onChangeHandler2}
          >
            <option>{props?.startingValue}</option>
            {subs?.map((option, index) => (
              <option value={index} key={index}>
                {option.name}
              </option>
            ))}
          </select>
          <span className="text-danger">
            {actualValue === true ? props.title + " Is Required" : ""}
          </span>
        </div>
      </div>
    </div>
  );
};

export const SelectInputCriteria2nd = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const [theTitle, setTheTitle] = useState("Select Activity / Hitamo igikorwa");
  const [subs, setSubs] = useState([]);
  const [zero, setZero] = useState(0);
  let actualValue = false;
  if (props.value === "" && props.isClicked == true) {
    actualValue = true;
  }

  const onChangeHandler = (event) => {
    let value;
    if (event.target) {
      value = event.target.value;
    } else {
      value = event;
    }
    setTheTitle(props?.options[event.target.value].name);
    if (props?.haschildren === "true") {
      setSubs(props?.subcriteriass?.criteriaValues[value]?.subCriteriaValues);
    }
    props.changed(value, {
      criteria: props?.criteria,
      criteriaId: props?.criteriaId,
      criteriaValue: props?.options[event.target.value].name,
      criteriaValueId: props?.options[event.target.value].uuid,
      haschildren: props?.haschildren,
      //  subss: props?.subcriteriass?.criteriaValues,
    });
  };

  if (props?.haschildren === "true") {
  }

  // useEffect(() => {}, [criteriaValueSubs]);

  const onChangeHandler2 = (event) => {
    let value;
    if (event.target) {
      value = event.target.value;
    } else {
      value = event;
    }
    props.childchanged(value, {
      criteria: props?.subcriteriass?.criteriaValues[props?.id]?.name,
      criteriaId: props?.subcriteriass?.criteriaValues[props?.id]?.uuid,
      criteriaValue:
        props?.subcriteriass?.criteriaValues[props?.id]?.subCriteriaValues[
          event.target.value
        ]?.name,
      criteriaValueId:
        props?.subcriteriass?.criteriaValues[props?.id]?.subCriteriaValues[
          event.target.value
        ]?.uuid,
      parentId: props?.criteriaId,
      haschildren: props?.haschildren,
    });
  };

  const selectRef = useRef();
  const selectChildRef = useRef();
  const selectChiRef = useRef();

  useImperativeHandle(ref, () => ({
    submit() {
      const select = selectRef.current;
      const thId = Number(select.id) + 21;
      const thVal = document.getElementById(thId).value;

      let childName;
      let childId;
      if (thVal === 0 || thVal >= 0) {
        childName = subs[thVal]?.name;
        childId = subs[thVal]?.uuid;
      }
      let criteria = props?.criteria;
      let criteriaId = props?.criteriaId;
      let criteriaValue = props?.options[selectRef.current.value]?.name;
      let criteriaValueId = props?.options[selectRef.current.value]?.uuid;
      let haschildren = props?.haschildren;
      let criteriaChild = props?.options[selectRef.current.value]?.name;
      let criteriaIdChild = props?.options[selectRef.current.value]?.uuid;
      let criteriaValueChild = childName;
      let criteriaValueIdChild = childId;

      if (haschildren === "true") {
        return [
          {
            criteria,
            criteriaId,
            criteriaValue,
            criteriaValueId,
            haschildren,
          },
          {
            criteria: criteriaChild,
            criteriaId: criteriaIdChild,
            criteriaValue: criteriaValueChild,
            criteriaValueId: criteriaValueIdChild,
          },
        ];
      } else {
        return {
          criteria,
          criteriaId,
          criteriaValue,
          criteriaValueId,
          haschildren,
        };
      }
    },
  }));

  return (
    <div className="">
      <div className={props?.haschildren === "true" ? "row d-flex" : ""}>
        <div
          className={
            props?.haschildren === "true" ? "col-sm-10 col-md-6" : "col-md-11"
          }
        >
          <span className="bdf-bluefontcolor">{props.title}</span>
          <select
            ref={selectRef}
            id={props?.id}
            className={classnames("w-full form-control", {
              "w-full form-control border-danger": actualValue,
            })}
            value={props.value}
            onChange={onChangeHandler}
          >
            <option>{props?.startingValue}</option>
            {props?.options.map((option, index) => (
              <option value={index} key={index}>
                {option.name}
              </option>
            ))}
          </select>
          <span className="text-danger">
            {actualValue === true ? props.title + " Is Required" : ""}
          </span>
        </div>
        <div
          className={
            props?.haschildren === "true" ? "col-sm-10 col-md-6" : "d-none"
          }
        >
          <span className="bdf-bluefontcolor">{theTitle}</span>
          <select // ref={selectChildRef}
            id={props?.id + 21}
            className={classnames("w-full form-control", {
              "w-full form-control border-danger": actualValue,
            })}
            value={props.childvalue}
            onChange={onChangeHandler2}
          >
            <option>{props?.startingValue}</option>
            {subs?.map((option, index) => (
              <option value={index} key={index}>
                {option.name}
              </option>
            ))}
          </select>
          <span className="text-danger">
            {actualValue === true ? props.title + " Is Required" : ""}
          </span>
        </div>
      </div>
    </div>
  );
});

export const InputDate = function (props) {
  let actualValue = false;
  let reporttype = props?.reporttype;
  let msg = props.title + " Is Required";
  if (props.value == "" && props.isClicked == true) {
    actualValue = true;
  }
  var date = new Date();
  var dat = new Date(props?.value);
  var thedate = new Date(props?.value);
  const newDate = date.getTime() - thedate.getTime();
  const years = Math.floor(newDate / (1000 * 60 * 60 * 24) / 365);
  if (props?.datetype == "dob") {
    const years = Math.floor(newDate / (1000 * 60 * 60 * 24));
  }

  const pdldate = thedate.setDate(thedate.getDay());

  date.setDate(date.getDate());
  let daty = date.getDay();
  if (thedate.getTime() > date.getTime()) {
    actualValue = true;
    msg =
      props.title + " Can not be in the future. d" + date + " td " + thedate;
  } else {
    if (reporttype === "pdl" && Number(dat.getDay()) != 6) {
      actualValue = true;
      msg = props.title;
    }
    if (
      reporttype === "daily" &&
      (Number(dat.getDay()) > 5 || Number(dat.getDay()) < 1)
    ) {
      actualValue = true;
      msg = "Daily Report must be for working days (Monday-Friday)";
    }
    if (reporttype === "weekly" && Number(dat.getDay()) != 5) {
      actualValue = true;
      msg = "Daily Report must be reported on Friday";
    }
  }
  return (
    <div className="">
      <span className="bdf-bluefontcolor">{props.title}</span>
      <RequiredStar />
      <input
        type="date"
        min={new Date()}
        max={new Date()}
        name={props.name}
        value={props.value}
        className={classnames("form-control", {
          "form-control border-danger": actualValue,
        })}
        placeholder={props.placeholder}
        onChange={props.onChange}
      />
      <span className="text-danger">{actualValue === true ? msg : ""}</span>
    </div>
  );
};

export const InputDate2 = function (props) {
  const [date, setDate] = useState(new Date());
  let actualValue = false;
  let msg = props.title + " Is Required";
  if (props.value == "" && props.isClicked == true) {
    actualValue = true;
  }
  // var date = new Date();
  var thedate = new Date(props?.value);
  const newDate = date.getTime() - thedate.getTime();
  const years = Math.floor(newDate / (1000 * 60 * 60 * 24) / 365);
  if (props?.datetype == "dob") {
    const years = Math.floor(newDate / (1000 * 60 * 60 * 24));
  }
  date.setDate(date.getDate());

  if (props?.reporttype === "pdl" && thedate.getDay() !== 6) {
    actualValue = true;
    msg = props.title + " Must be on Saturday";
  } else if (props?.periodicReport === "Daily" && (thedate.getDay() <1 || thedate.getDay() > 5)) {
    actualValue = true;
    msg = props.title;
  } else if (props?.periodicReport === "weeklyEnd" && thedate.getDay() !==5 ) {
    actualValue = true;
    msg = props.title;
  }else if (props?.periodicReport === "weeklyStart" && thedate.getDay() !==1 ) {
    actualValue = true;
    msg = props.title;
  }
  else if (thedate.getTime() > date.getTime() && props.isClicked == true) {
    actualValue = true;
    msg = props.title + " Can not be in the future";
  }
  return (
    <div className="w-100">
      <div>
        <span className="bdf-bluefontcolor">{props.title}</span>
        <RequiredStar />
      </div>
      <DatePicker
        className="form-control"
        onChange={props?.onChange}
        value={props?.value}
        dateFormat="dd/MM/yyyy"
        selected={props.value}
        showYearDropdown
        yearDropdownItemNumber={80}
        scrollableYearDropdown
        maxDate={new Date()}
        showMonthDropdown
        dropdownMode="select"
      />
      <div className="text-danger">{actualValue === true ? msg : ""}</div>
    </div>
  );
};

export const InputDate2Search = function (props) {
  const [date, setDate] = useState(new Date());
  let actualValue = false;
  let msg = props.title + " Is Required";
  if (props.value == "" && props.isClicked == true) {
    actualValue = true;
  }
  // var date = new Date();
  var thedate = new Date(props?.value);
  const newDate = date.getTime() - thedate.getTime();
  const years = Math.floor(newDate / (1000 * 60 * 60 * 24) / 365);
  if (props?.datetype == "dob") {
    const years = Math.floor(newDate / (1000 * 60 * 60 * 24));
  }
  date.setDate(date.getDate());
  return (
    <div className="w-100">
      <span className="bdf-bluefontcolor">{props.title}</span>
      <RequiredStar />
      <DatePicker
        className="form-control"
        onChange={props?.onChange}
        value={props?.value}
        dateFormat="dd/MM/yyyy"
        selected={props.value}
        showYearDropdown
        yearDropdownItemNumber={80}
        scrollableYearDropdown
        maxDate={new Date()}
        showMonthDropdown
        dropdownMode="select"
      />
      <span className="text-danger">{actualValue === true ? msg : ""}</span>
    </div>
  );
};

export const UplodadFile = function (props) {
  let accept = ".jpeg, .jpg, .png, .svg";
  let classes = "";
  let required = props.required;
  let actualValue = false;
  if (props.value == "" && props.isClicked == true) {
    actualValue = true;
  }

  if (props?.accept) {
    accept = props?.accept;
  }

  if (props.updating == constants.isTrue) {
    classes = "d-none";
    required = false;
  }

  return (
    <div className={classes}>
      <span className="bdf-bluefontcolor">{props.title}</span>
      <input
        required={required}
        type="file"
        name={props.name}
        accept={accept}
        className={classnames("form-control", {
          "form-control border-danger": actualValue,
        })}
        placeholder={props.placeholder}
        onChange={props.onChange}
      />
      <span className="text-danger">
        {actualValue === true ? props.title + " Is Required" : ""}
      </span>
    </div>
  );
};

export const MiniTitle = function(props){
  return(
    <label className="bdf-bluefontcolor-title-2 p-2">{props?.title}</label>
  )
}

export const InputTextArea = function (props) {
  let actualValue = false;
  if (props.value == "" && props.isClicked == true) {
    actualValue = true;
  }

  return (
    <div className="">
      <span className="bdf-bluefontcolor">{props?.title}</span>
      <textarea
        className={classnames("form-control", {
          "form-control border-danger": actualValue,
        })}
        name={props?.name}
        value={props?.value}
        rows={3}
        placeholder={props?.placeholder}
        onChange={props?.onChange}
      ></textarea>
      <span className="text-danger">
        {actualValue === true ? props.title + " Is Required" : ""}
      </span>
    </div>
  );
};

export const SelectRadioButton = (props) => {
  let actualValue = false;
  if (props.value === "" && props.isClicked == true) {
    actualValue = true;
  }
  return (
    <div className="">
      <label className="bdf-bluefontcolor">{props.title} </label>
      <div className="d-flex row" onChange={props.onChange}>
        <div className="w-50">
          <input
            type="radio"
            value={props.option1}
            id="true"
            name={props.name}
          />
          <label for="true" className="px-2">
            {props.option1}
          </label>
        </div>
        <div className="w-50">
          <input
            type="radio"
            value={props.option2}
            id="false"
            name={props.name}
          />
          <label for="false" className="px-2">
            {props.option2}
          </label>
        </div>
      </div>
      <span className="text-danger">
        {actualValue === true ? "Select One Option" : ""}
      </span>
    </div>
  );
};

export const SubmitButton = function (props) {
  let classes = props?.classes;
  return (
    <button
      disabled={props?.disabled}
      onClick={props?.onSubmit}
      type="submit"
      className={"btns col " + classes}
    >
      {props.action}
    </button>
  );
};

export const ActivateButton = function (props) {
  let classes = "d-none";
  let title = "";
  if (props?.status) {
    if (props?.status === "active") {
      classes = "d-show btn btn-danger";
      title = "Desactivate";
    } else {
      classes = "d-show btn btn-success";
      title = "Activate";
    }
  }
  return (
    <button
      disabled={props?.disabled}
      onClick={props?.onSubmit}
      type="submit"
      className={"col " + classes}
    >
      {title}
    </button>
  );
};

export const PageLink = function (props) {
  return (
    <NavLink
      to={props?.page}
      className="bg-bdf-blue px-5 py-2 text-white font-bold"
    >
      {props?.title}
    </NavLink>
  );
};

export const InputTextSecret = function (props) {
  return (
    <input
      type="password"
      name={props?.name}
      value={props?.value}
      className="form-control"
      placeholder={props?.placeholder}
      onChange={props?.onChange}
    />
  );
};

export const EditButton = function (props) {
  let classes = props?.classes;
  let idpath = props?.id;
  if (props?.parentId) {
    idpath = props?.parentId + "/" + props?.id;
  }
  return (
    <NavLink
      to={props?.page + "/" + idpath}
      className="bg-bdf-blue py-1 px-2 text-white w-auto"
    >
      {props?.title}
    </NavLink>
  );
};

export const ViewContentBtn = function (props) {
  let classes = props?.classes;
  let idpath = props?.id;
  if (props?.parentId) {
    idpath = props?.parentId + "/" + props?.id;
  }
  return (
    <NavLink
      to={props?.page + "/" + idpath}
      className="bg-success py-1 px-2 text-white w-auto"
    >
      {props?.title}
    </NavLink>
  );
};

export const ViewButton = function (props) {
  let classes = props?.classes;
  return (
    <button
      type="submit"
      onClick={props.onClick}
      className="py-1 px-2 text-white w-50 bg-success w-auto"
    >
      {props?.title}
    </button>
  );
};
