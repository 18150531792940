import { updateObject } from "../../utils/utility";
import {
  GET_PDLREPORTS_START,
  GET_PDLREPORTS_SUCCESS,
  GET_ONE_PDLREPORTS_SUCCESS,
  GET_PDLREPORTS_FAIL,
  GET_FAILED_PDLREPORT
} from "../actions/pdlreport";

const initialReducer = {
  pdlreport: null,
  pdlreports: [],
  pdlreportsLoading: false,
  pdlreportsFail: null,
  failedReport: null,
};

const getPDLReportsStart = (state, action) => {
  return updateObject(state, {
    pdlreports: [],
    pdlreport: null,
    pdlreportsLoading: true,
    pdlreportsFail: null,
  });
};

const getPDLReportsSuccess = (state, action) => {
  return updateObject(state, {
    pdlreports: action.payload,
    pdlreportsLoading: false,
    pdlreportsFail: null,
    pdlreport: null,
  });
};

const getOnePDLReportsSuccess = (state, action) => {
  return updateObject(state, {
    pdlreport: action.payload,
    pdlreports: [],
    pdlreportsLoading: false,
    pdlreportsFail: null,
  });
};

const getPDLReportsFail = (state, action) => {
  return updateObject(state, {
    pdlreports: [],
    pdlreport: null,
    pdlreportsLoading: false,
    pdlreportsFail: action.payload,
  });
};

const getFailedReport = (state, action) => {
  return updateObject(state, {
    pdlreports: [],
    pdlreport: null,
    pdlreportsLoading: false,
    pdlreportsFail: null,
    failedReport: action.payload,
  });
};

const reducer = (state = initialReducer, action) => {
  switch (action.type) {
    case GET_PDLREPORTS_START:
      return getPDLReportsStart(state, action);
    case GET_PDLREPORTS_SUCCESS:
      return getPDLReportsSuccess(state, action);
    case GET_ONE_PDLREPORTS_SUCCESS:
      return getOnePDLReportsSuccess(state, action);
    case GET_PDLREPORTS_FAIL:
      return getPDLReportsFail(state, action);
    case GET_FAILED_PDLREPORT:
      return getFailedReport(state, action);
    default:
      return state;
  }
};

export default reducer;
