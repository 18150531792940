import * as actions from "../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import {
  InputDate,
  InputDate2,
  InputText,
  InputText2,
  InputText3,
  InputTextArea,
  InputTextSecret,
  MiniTitle,
  SelectInput,
  SubmitButton,
} from "../components/inputs";
import Header from "../components/header";
import AdminHeader from "../components/adminheader";
import {
  Beadcrumb,
  ObjectDetails,
  PageTitle,
  formatDecimals,
} from "../components/utils";
import { ReactNotifications } from "react-notifications-component";
import { displayName } from "react-quill";
import {
  errorNotification,
  successNotification,
} from "../components/notifications";
import constants, { isTrue } from "../components/constants";

export const PeriodicReports = function (props) {
  const dispatch = useDispatch();
  const [username, setusername] = useState("");
  const [password, setpassword] = useState("");
  const user = useSelector((state) => state.auth.user);
  const displayName = useSelector((state) => state.auth.displayName);
  const branch = useSelector((state) => state.auth.branch);
  const token = useSelector((state) => state.auth.token);
  const error = useSelector((state) => state.auth.error);
  const periodicreport = useSelector((state) => state.report.report);
  const pdlreportsFail = useSelector((state) => state.pdlreport.pdlreportsFail);
  const WORKSPACE = "PERIODIC REPORTS";
  const [clicked, setclicked] = useState();
  const [submitted, setsubmitted] = useState(false);
  const [totalcandidates, settotalcandidates] = useState("");
  const [reportingdate, setreportingdate] = useState(new Date());
  const [endingdate, setendingdate] = useState(new Date());
  const [totalparticipants, settotalparticipants] = useState("");
  const [amtcollected, settamtcollected] = useState("");
  const [comments, setcomments] = useState("");
  const [periodtype, setperiodtype] = useState("");

  const reportObject = useSelector((state) => state.report.report);
  const reportsLoading = useSelector((state) => state.report.reportsLoading);

  const [guaranteeReviewed, setGuaranteeReviewed] = useState(
    reportObject?.guaranteeReviewed ? reportObject?.guaranteeReviewed : ""
  );
  const [guaranteeAnalyzed, setGuaranteeAnalyzed] = useState(
    reportObject?.guaranteeAnalyzed ? reportObject?.guaranteeAnalyzed : ""
  );
  const [guaranteeApproved, setGuaranteeApproved] = useState(
    reportObject?.guaranteeApproved ? reportObject?.guaranteeApproved : ""
  );
  const [guaranteeCommissions, setGuaranteeCommissions] = useState(
    reportObject?.guaranteeCommissions ? reportObject?.guaranteeCommissions : ""
  );
  const [erfReviewed, setErfReviewed] = useState(
    reportObject?.erfReviewed ? reportObject?.erfReviewed : ""
  );
  const [erfAnalyzed, setErfAnalyzed] = useState(
    reportObject?.erfAnalyzed ? reportObject?.erfAnalyzed : ""
  );
  const [erfApproved, setErfApproved] = useState(
    reportObject?.erfApproved ? reportObject?.erfApproved : ""
  );
  const [leasingReviewed, setLeasingReviewed] = useState(
    reportObject?.leasingReviewed ? reportObject?.leasingReviewed : ""
  );
  const [leasingAnalyzed, setLeasingAnalyzed] = useState(
    reportObject?.leasingAnalyzed ? reportObject?.leasingAnalyzed : ""
  );
  const [leasingApproved, setLeasingApproved] = useState(
    reportObject?.leasingApproved ? reportObject?.leasingApproved : ""
  );
  const [microLeasingReviewed, setMicroLeasingReviewed] = useState(
    reportObject?.microLeasingReviewed ? reportObject?.microLeasingReviewed : ""
  );
  const [microLeasingAnalyzed, setMicroLeasingAnalyzed] = useState(
    reportObject?.microLeasingAnalyzed ? reportObject?.microLeasingAnalyzed : ""
  );
  const [microLeasingApproved, setMicroLeasingApproved] = useState(
    reportObject?.microLeasingApproved ? reportObject?.microLeasingApproved : ""
  );
  const [agribusinessReviewed, setAgribusinessReviewed] = useState(
    reportObject?.agribusinessReviewed ? reportObject?.agribusinessReviewed : ""
  );
  const [agribusinessAnalyzed, setAgribusinessAnalyzed] = useState(
    reportObject?.agribusinessAnalyzed ? reportObject?.agribusinessAnalyzed : ""
  );
  const [agribusinessApproved, setAgribusinessApproved] = useState(
    reportObject?.agribusinessApproved ? reportObject?.agribusinessApproved : ""
  );
  const [claims, setClaims] = useState(
    reportObject?.claims ? reportObject?.claims : ""
  );
  const [draftedContracts, setDraftedContracts] = useState(
    reportObject?.draftedContracts ? reportObject?.draftedContracts : ""
  );
  const [draftedNotifications, setDraftedNotifications] = useState(
    reportObject?.draftedNotifications ? reportObject?.draftedNotifications : ""
  );
  const [projectsMonitored, setProjectsMonitored] = useState(
    reportObject?.projectsMonitored ? reportObject?.projectsMonitored : ""
  );

  const [rddpDisbursement, setRddpDisbursement] = useState(
    reportObject?.rddpDisbursement ? reportObject?.rddpDisbursement : ""
  );
  const [otherDisbursement, setOtherDisbursement] = useState(
    reportObject?.otherDisbursement ? reportObject?.otherDisbursement : ""
  );
  const [itAndOtherServicesRevenues, setItAndOtherServicesRevenues] = useState(
    reportObject?.itAndOtherServicesRevenues
      ? reportObject?.itAndOtherServicesRevenues
      : ""
  );
  const [saccoNormalRecoveries, setSaccoNormalRecoveries] = useState(
    reportObject?.saccoNormalRecoveries
      ? reportObject?.saccoNormalRecoveries
      : ""
  );
  const [saccoArrearsRecoveries, setSaccoArrearsRecoveries] = useState(
    reportObject?.saccoArrearsRecoveries
      ? reportObject?.saccoArrearsRecoveries
      : ""
  );
  const [leasingNormalRecoveries, setLeasingNormalRecoveries] = useState(
    reportObject?.leasingNormalRecoveries
      ? reportObject?.leasingNormalRecoveries
      : ""
  );
  const [leasingArrearsRecoveries, setLeasingArrearsRecoveries] = useState(
    reportObject?.leasingArrearsRecoveries
      ? reportObject?.leasingArrearsRecoveries
      : ""
  );
  const [microLeasingNormalRecoveries, setMicroLeasingNormalRecoveries] =
    useState(
      reportObject?.microLeasingNormalRecoveries
        ? reportObject?.microLeasingNormalRecoveries
        : ""
    );
  const [microLeasingArrearsRecoveries, setMicroLeasingArrearsRecoveries] =
    useState(
      reportObject?.microLeasingArrearsRecoveries
        ? reportObject?.microLeasingArrearsRecoveries
        : ""
    );
  const [agribusinessNormalRecoveries, setAgribusinessNormalRecoveries] =
    useState(
      reportObject?.agribusinessNormalRecoveries
        ? reportObject?.agribusinessNormalRecoveries
        : ""
    );
  const [agribusinessArrearsRecoveries, setAgribusinessArrearsRecoveries] =
    useState(
      reportObject?.agribusinessArrearsRecoveries
        ? reportObject?.agribusinessArrearsRecoveries
        : ""
    );

  // setThePeriod=(e)=>{

  // }

  onsubmit = (e) => {
    e.preventDefault();
    setsubmitted(isTrue);
    if (
      guaranteeReviewed?.toString()?.trim() === "" ||
      guaranteeAnalyzed?.toString()?.trim() === "" ||
      guaranteeApproved?.toString()?.trim() === "" ||
      guaranteeCommissions?.toString()?.trim() === "" ||
      erfReviewed?.toString()?.trim() === "" ||
      erfAnalyzed?.toString()?.trim() === "" ||
      erfApproved?.toString()?.trim() === "" ||
      leasingReviewed?.toString()?.trim() === "" ||
      leasingAnalyzed?.toString()?.trim() === "" ||
      leasingApproved?.toString()?.trim() === "" ||
      microLeasingReviewed?.toString()?.trim() === "" ||
      microLeasingAnalyzed?.toString()?.trim() === "" ||
      microLeasingApproved?.toString()?.trim() === "" ||
      agribusinessReviewed?.toString()?.trim() === "" ||
      agribusinessAnalyzed?.toString()?.trim() === "" ||
      agribusinessApproved?.toString()?.trim() === "" ||
      claims?.toString()?.trim() === "" ||
      draftedContracts?.toString()?.trim() === "" ||
      draftedNotifications?.toString()?.trim() === "" ||
      projectsMonitored?.toString()?.trim() === "" ||
      rddpDisbursement?.toString()?.trim() === "" ||
      otherDisbursement?.toString()?.trim() === "" ||
      itAndOtherServicesRevenues?.toString()?.trim() === "" ||
      saccoNormalRecoveries?.toString()?.trim() === "" ||
      saccoArrearsRecoveries?.toString()?.trim() === "" ||
      leasingNormalRecoveries?.toString()?.trim() === "" ||
      leasingArrearsRecoveries?.toString()?.trim() === "" ||
      microLeasingNormalRecoveries?.toString()?.trim() === "" ||
      microLeasingArrearsRecoveries?.toString()?.trim() === "" ||
      agribusinessNormalRecoveries?.toString()?.trim() === "" ||
      agribusinessArrearsRecoveries?.toString()?.trim() === "" ||
      reportingdate === ""
    ) {
      setclicked(true);
    } else {
      if (window.confirm("Are you sure you want submit this report?")) {
        const obj = {
          submittedBy: displayName,
          reportingdate,
          branchName: branch,
          guaranteeReviewed,
          guaranteeAnalyzed,
          guaranteeApproved,
          guaranteeCommissions,
          erfReviewed,
          erfAnalyzed,
          erfApproved,
          leasingReviewed,
          leasingAnalyzed,
          leasingApproved,
          microLeasingReviewed,
          microLeasingAnalyzed,
          microLeasingApproved,
          agribusinessReviewed,
          agribusinessAnalyzed,
          agribusinessApproved,
          claims,
          draftedContracts,
          draftedNotifications,
          projectsMonitored,
          rddpDisbursement,
          otherDisbursement,
          itAndOtherServicesRevenues,
          saccoNormalRecoveries,
          saccoArrearsRecoveries,
          leasingNormalRecoveries,
          leasingArrearsRecoveries,
          microLeasingNormalRecoveries,
          microLeasingArrearsRecoveries,
          agribusinessNormalRecoveries,
          agribusinessArrearsRecoveries,
          reportingdate,
          comments,
          period:periodtype,
          startingDate:endingdate 
        };
        // dispatch(actions.createPDLReports(obj, token));
        dispatch(actions.initReport(obj));
        dispatch(actions.submitReports(obj));
        setAgribusinessAnalyzed("");
        setAgribusinessApproved("");
        setAgribusinessReviewed("");
        setGuaranteeReviewed("");
        setGuaranteeAnalyzed("");
        setGuaranteeApproved("");
        setGuaranteeCommissions("");
        setErfReviewed("");
        setErfAnalyzed("");
        setErfApproved("");
        setLeasingReviewed("");
        setLeasingAnalyzed("");
        setLeasingApproved("");
        setMicroLeasingAnalyzed("");
        setMicroLeasingApproved("");
        setMicroLeasingReviewed("");
        setClaims("");
        setProjectsMonitored("");
        setDraftedNotifications("");
        setDraftedContracts("");
        setSaccoArrearsRecoveries("");
        setSaccoNormalRecoveries("");
        setAgribusinessNormalRecoveries("");
        setAgribusinessArrearsRecoveries("");
        setLeasingNormalRecoveries("");
        setLeasingArrearsRecoveries("");
        setMicroLeasingNormalRecoveries("");
        setMicroLeasingArrearsRecoveries("");
        setcomments("");
        setRddpDisbursement("");
        setOtherDisbursement("");
        setItAndOtherServicesRevenues("")
      } else {
      }
    }
  };

  const history = useHistory();
  const periods = [
    { id: 1, value: "Daily" },
    { id: 2, value: "Weekly" },
    { id: 3, value: "Monthly" },
  ];
  useEffect(() => {
    // if (user !== null) {
    //   history.push("/police-reports");
    // }
    // settamtcollected(totalparticipants * 1000);
    // if (pdlreport?.uuid !== undefined && pdlreport?.uuid !== null) {
    //   successNotification("Report");
    // }
    console.log("pdlreportsFail: ", pdlreportsFail);
    if(periodicreport?.uuid !== undefined && periodicreport?.uuid !== null){
      successNotification("Periodic Report");
    }
    // if (pdlreportsFail !== null) {
    //   errorNotification("PDL Report");
    // }
    // const date1 = new Date(reportingdate);
    // date1.setDate(date1.getDate() + 4);
    // setendingdate(new Date(date1));
  }, [user, reportingdate, periodicreport]);
  return (
    <>
      <AdminHeader user={user} />
      <ReactNotifications />
      <main className="container" id="main">
        <Beadcrumb />
        <div className="pt-5">
          <PageTitle pagetitle={WORKSPACE} />
        </div>
        <div className="" data-aos="fade-up ">
          <div className="row d-flex php-email-form">
            <div className="col-md-12">
              <div className="row pt-3">
                <div className="col-sm-10 col-md-6">
                  <div className="row">
                    <div className="col-md-10">
                      <div className="form-group py-1">
                        <SelectInput
                          title="Period"
                          startingValue="Select Period"
                          options={periods}
                          value={periodtype}
                          changed={setperiodtype}
                          isClicked={clicked}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-10 col-md-6">
                  <div className={periodtype === "Daily" ? "d-show" : "d-none"}>
                    <InputDate2
                      title={"Reporting Date (must be a Working Day)"}
                      inptype="number"
                      value={reportingdate}
                      periodicReport="Daily"
                      name="reportingdate"
                      placeholder={"Reporting Date"}
                      isClicked={clicked}
                      onChange={(e) => {
                        setreportingdate(e);
                        setclicked(false);
                      }}
                    />
                  </div>
                  <div
                    className={
                      periodtype === "Weekly" ? "d-show row" : "d-none"
                    }
                  >
                    <div className="col-md-6">
                      <InputDate2
                        title={"Starting Date (Monday)"}
                        inptype="number"
                        periodicReport="weeklyStart"
                        value={reportingdate}
                        name="reportingdate"
                        placeholder={"Reporting Date"}
                        isClicked={clicked}
                        onChange={(e) => {
                          setreportingdate(e);
                          setclicked(false);
                        }}
                      />
                    </div>
                    <div className="col-md-6">
                      <InputDate2
                        title={"Ending Date (Friday)"}
                        inptype="number"
                        periodicReport="weeklyEnd"
                        value={endingdate}
                        name="endingdate"
                        placeholder={"Reporting Date"}
                        isClicked={clicked}
                        onChange={(e) => {
                          setendingdate(e);
                          setclicked(false);
                        }}
                      />
                    </div>
                  </div>
                  <div
                    className={periodtype === "Monthly" ? "d-show" : "d-none"}
                  >
                    <InputDate2
                      title={"Reporting Date (Last day of the Month)"}
                      inptype="number"
                      periodicReport="monthly"
                      value={reportingdate}
                      name="reportingdate"
                      placeholder={"Reporting Date"}
                      isClicked={clicked}
                      onChange={(e) => {
                        setreportingdate(e);
                        setclicked(false);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-3">
                    <div className="pt-1">
                      <MiniTitle title="Guarantee" />
                      <InputText3
                        title={"Reviewed"}
                        inptype="number"
                        value={guaranteeReviewed}
                        name="guaranteeReviewed"
                        placeholder={"...."}
                        isClicked={clicked}
                        onChange={(e) => {
                          setGuaranteeReviewed(e.target.value);
                          setclicked(false);
                        }}
                      />
                      <InputText3
                        title={"Analyzed"}
                        inptype="number"
                        value={guaranteeAnalyzed}
                        name="guaranteeAnalyzed"
                        placeholder={"...."}
                        isClicked={clicked}
                        onChange={(e) => {
                          setGuaranteeAnalyzed(e.target.value);
                          setclicked(false);
                        }}
                      />
                      <InputText3
                        title={"Approved"}
                        inptype="number"
                        value={guaranteeApproved}
                        name="guaranteeApproved"
                        placeholder={"...."}
                        isClicked={clicked}
                        onChange={(e) => {
                          setGuaranteeApproved(e.target.value);
                          setclicked(false);
                        }}
                      />
                      <InputText3
                        title={"Commissions"}
                        inptype="number"
                        value={guaranteeCommissions}
                        name="guaranteeCommissions"
                        placeholder={"...."}
                        isClicked={clicked}
                        onChange={(e) => {
                          setGuaranteeCommissions(e.target.value);
                          setclicked(false);
                        }}
                      />
                    </div>
                    <div className="pt-1">
                      <MiniTitle title="ERF" />
                      <InputText3
                        title={"Reviewed"}
                        inptype="number"
                        value={erfReviewed}
                        name="erfReviewed"
                        placeholder={"...."}
                        isClicked={clicked}
                        onChange={(e) => {
                          setErfReviewed(e.target.value);
                          setclicked(false);
                        }}
                      />
                      <InputText3
                        title={"Analyzed"}
                        inptype="number"
                        value={erfAnalyzed}
                        name="erfAnalyzed"
                        placeholder={"...."}
                        isClicked={clicked}
                        onChange={(e) => {
                          setErfAnalyzed(e.target.value);
                          setclicked(false);
                        }}
                      />
                      <InputText3
                        title={"Approved"}
                        inptype="number"
                        value={erfApproved}
                        name="erfApproved"
                        placeholder={"...."}
                        isClicked={clicked}
                        onChange={(e) => {
                          setErfApproved(e.target.value);
                          setclicked(false);
                        }}
                      />
                    </div>
                    <div className="pt-1">
                      <MiniTitle title="Agribusiness" />
                      <InputText3
                        title={"Reviewed"}
                        inptype="number"
                        value={agribusinessReviewed}
                        name="agribusinessReviewed"
                        placeholder={"...."}
                        isClicked={clicked}
                        onChange={(e) => {
                          setAgribusinessReviewed(e.target.value);
                          setclicked(false);
                        }}
                      />
                      <InputText3
                        title={"Analyzed"}
                        inptype="number"
                        value={agribusinessAnalyzed}
                        name="agribusinessAnalyzed"
                        placeholder={"...."}
                        isClicked={clicked}
                        onChange={(e) => {
                          setAgribusinessAnalyzed(e.target.value);
                          setclicked(false);
                        }}
                      />
                      <InputText3
                        title={"Approved"}
                        inptype="number"
                        value={agribusinessApproved}
                        name="agribusinessApproved"
                        placeholder={"...."}
                        isClicked={clicked}
                        onChange={(e) => {
                          setAgribusinessApproved(e.target.value);
                          setclicked(false);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="pt-1">
                      <MiniTitle title="Leasing" />
                      <InputText3
                        title={"Reviewed"}
                        inptype="number"
                        value={leasingReviewed}
                        name="leasingReviewed"
                        placeholder={"...."}
                        isClicked={clicked}
                        onChange={(e) => {
                          setLeasingReviewed(e.target.value);
                          setclicked(false);
                        }}
                      />
                      <InputText3
                        title={"Analyzed"}
                        inptype="number"
                        value={leasingAnalyzed}
                        name="leasingAnalyzed"
                        placeholder={"...."}
                        isClicked={clicked}
                        onChange={(e) => {
                          setLeasingAnalyzed(e.target.value);
                          setclicked(false);
                        }}
                      />
                      <InputText3
                        title={"Approved"}
                        inptype="number"
                        value={leasingApproved}
                        name="leasingApproved"
                        placeholder={"...."}
                        isClicked={clicked}
                        onChange={(e) => {
                          setLeasingApproved(e.target.value);
                          setclicked(false);
                        }}
                      />
                    </div>
                    <div className="pt-1">
                      <MiniTitle title="Micro Leasing" />
                      <InputText3
                        title={"Reviewed"}
                        inptype="number"
                        value={microLeasingReviewed}
                        name="microLeasingReviewed"
                        placeholder={"...."}
                        isClicked={clicked}
                        onChange={(e) => {
                          setMicroLeasingReviewed(e.target.value);
                          setclicked(false);
                        }}
                      />
                      <InputText3
                        title={"Analyzed"}
                        inptype="number"
                        value={microLeasingAnalyzed}
                        name="microLeasingAnalyzed"
                        placeholder={"...."}
                        isClicked={clicked}
                        onChange={(e) => {
                          setMicroLeasingAnalyzed(e.target.value);
                          setclicked(false);
                        }}
                      />
                      <InputText3
                        title={"Approved"}
                        inptype="number"
                        value={microLeasingApproved}
                        name="microLeasingApproved"
                        placeholder={"...."}
                        isClicked={clicked}
                        onChange={(e) => {
                          setMicroLeasingApproved(e.target.value);
                          setclicked(false);
                        }}
                      />
                    </div>
                    <div className="pt-1">
                      <MiniTitle title="Others" />
                      <InputText3
                        title={"Claims"}
                        inptype="number"
                        value={claims}
                        name="claims"
                        placeholder={"...."}
                        isClicked={clicked}
                        onChange={(e) => {
                          setClaims(e.target.value);
                          setclicked(false);
                        }}
                      />
                      <InputText3
                        title={"Drafted Contrats"}
                        inptype="number"
                        value={draftedContracts}
                        name="draftedContracts"
                        placeholder={"...."}
                        isClicked={clicked}
                        onChange={(e) => {
                          setDraftedContracts(e.target.value);
                          setclicked(false);
                        }}
                      />
                      <InputText3
                        title={"Drafted Notifications"}
                        inptype="number"
                        value={draftedNotifications}
                        name="draftedNotifications"
                        placeholder={"...."}
                        isClicked={clicked}
                        onChange={(e) => {
                          setDraftedNotifications(e.target.value);
                          setclicked(false);
                        }}
                      />
                      <InputText3
                        title={"Project Monitored"}
                        inptype="number"
                        value={projectsMonitored}
                        name="projectsMonitored"
                        placeholder={"...."}
                        isClicked={clicked}
                        onChange={(e) => {
                          setProjectsMonitored(e.target.value);
                          setclicked(false);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="pt-1">
                      <MiniTitle title="Sacco Refinancing Recoveries" />
                      <InputText3
                        title={"Normal"}
                        inptype="number"
                        value={saccoNormalRecoveries}
                        name="saccoNormalRecoveries"
                        placeholder={"...."}
                        isClicked={clicked}
                        onChange={(e) => {
                          setSaccoNormalRecoveries(e.target.value);
                          setclicked(false);
                        }}
                      />
                      <InputText3
                        title={"Arrears"}
                        inptype="number"
                        value={saccoArrearsRecoveries}
                        name="saccoArrearsRecoveries"
                        placeholder={"...."}
                        isClicked={clicked}
                        onChange={(e) => {
                          setSaccoArrearsRecoveries(e.target.value);
                          setclicked(false);
                        }}
                      />
                    </div>
                    <div className="pt-1">
                      <MiniTitle title="Agri-Business Recoveries" />
                      <InputText3
                        title={"Normal"}
                        inptype="number"
                        value={agribusinessNormalRecoveries}
                        name="agribusinessNormalRecoveries"
                        placeholder={"...."}
                        isClicked={clicked}
                        onChange={(e) => {
                          setAgribusinessNormalRecoveries(e.target.value);
                          setclicked(false);
                        }}
                      />
                      <InputText3
                        title={"Arrears"}
                        inptype="number"
                        value={agribusinessArrearsRecoveries}
                        name="agribusinessArrearsRecoveries"
                        placeholder={"...."}
                        isClicked={clicked}
                        onChange={(e) => {
                          setAgribusinessArrearsRecoveries(e.target.value);
                          setclicked(false);
                        }}
                      />
                    </div>
                    <div className="pt-1">
                      <MiniTitle title="Revenues" />
                      <InputText3
                        title={"IT & Other Services"}
                        inptype="number"
                        value={itAndOtherServicesRevenues}
                        name="itAndOtherServicesRevenues"
                        placeholder={"...."}
                        isClicked={clicked}
                        onChange={(e) => {
                          setItAndOtherServicesRevenues(e.target.value);
                          setclicked(false);
                        }}
                      />
                    </div>
                    <div className="pt-1">
                      <MiniTitle title="Disbursement" />
                      <InputText3
                        title={"RDDP"}
                        inptype="number"
                        value={rddpDisbursement}
                        name="rddpDisbursement"
                        placeholder={"...."}
                        isClicked={clicked}
                        onChange={(e) => {
                          setRddpDisbursement(e.target.value);
                          setclicked(false);
                        }}
                      />
                      <InputText3
                        title={"Other"}
                        inptype="number"
                        value={otherDisbursement}
                        name="otherDisbursement"
                        placeholder={"...."}
                        isClicked={clicked}
                        onChange={(e) => {
                          setOtherDisbursement(e.target.value);
                          setclicked(false);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="pt-1">
                      <MiniTitle title="Leasing Recoveries" />
                      <InputText3
                        title={"Normal"}
                        inptype="number"
                        value={leasingNormalRecoveries}
                        name="leasingNormalRecoveries"
                        placeholder={"...."}
                        isClicked={clicked}
                        onChange={(e) => {
                          setLeasingNormalRecoveries(e.target.value);
                          setclicked(false);
                        }}
                      />
                      <InputText3
                        title={"Arrears"}
                        inptype="number"
                        value={leasingArrearsRecoveries}
                        name="leasingArrearsRecoveries"
                        placeholder={"...."}
                        isClicked={clicked}
                        onChange={(e) => {
                          setLeasingArrearsRecoveries(e.target.value);
                          setclicked(false);
                        }}
                      />
                    </div>
                    <div className="pt-1">
                      <MiniTitle title="Micro-Leasing Recoveries" />
                      <InputText3
                        title={"Normal"}
                        inptype="number"
                        value={microLeasingNormalRecoveries}
                        name="microLeasingNormalRecoveries"
                        placeholder={"...."}
                        isClicked={clicked}
                        onChange={(e) => {
                          setMicroLeasingNormalRecoveries(e.target.value);
                          setclicked(false);
                        }}
                      />
                      <InputText3
                        title={"Arrears"}
                        inptype="number"
                        value={microLeasingArrearsRecoveries}
                        name="microLeasingArrearsRecoveries"
                        placeholder={"...."}
                        isClicked={clicked}
                        onChange={(e) => {
                          setMicroLeasingArrearsRecoveries(e.target.value);
                          setclicked(false);
                        }}
                      />
                      <div className="form-group pt-3">
                        <InputTextArea
                          title={"Comments"}
                          inptype="number"
                          value={comments}
                          name="comments"
                          placeholder={"comments"}
                          isClicked={clicked}
                          onChange={(e) => {
                            setcomments(e.target.value);
                            setclicked(false);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*   <div className="row d-flex">
            {objectsCriteriaLoading ? (
              <>
                <div className="w-100 text-center">
                  <MiSpinner />
                </div>
              </>
            ) : (
              <>
                {objectsCriteria?.map((object, index) => (
                  <div
                    className={
                      object?.haschildren === "true"
                        ? "col-sm-10 col-md-12"
                        : "col-sm-10 col-md-6"
                    }
                    key={index}
                  >
                    <div className="form-group py-1">
                      <SelectInputCriteria2nd
                        ref={addToRefs}
                        key={index}
                        id={index}
                        title={object?.criteria}
                        haschildren={object?.haschildren}
                        startingValue={"HITAMO/SELECT"}
                        options={object?.criteriaValues}
                        value={valuess[index + 1]}
                        childvalue={childvalues[index + 1]}
                        changed={()=>{}}
                        childchanged={()=>{}}
                        criteriaId={object?.id}
                        criteria={object?.criteria}
                        isClicked={clicked}
                        subcriteria={[]}
                        subcriteriass={object}
                        subcriteriassi={
                          object.criteriaValues[index]?.subCriteriaValues
                        }
                        criteriaTitle={thecriteriaTitle}
                      />
                    </div>
                  </div>
                ))}
              </>
            )}
          </div> */}
              <div className="pt-3">
                <div className="row gx-5"></div>
                <SubmitButton
                  classes="bg-success"
                  action={"Submit"}
                  onSubmit={onsubmit}
                />
              </div>
            </div>
            <div className="col-md-3 d-flex gy-5 ml-5 justify-content-end"></div>
          </div>
        </div>
      </main>
    </>
  );
};
