import axios from "../../axios-base";
import { successNotification } from "../../components/notifications";

export const GET_PDLREPORTS_SUCCESS = "GET_PDLREPORTS_SUCCESS";
export const GET_ONE_PDLREPORTS_SUCCESS = "GET_ONE_PDLREPORTS_SUCCESS";
export const GET_PDLREPORTS_START = "GET_PDLREPORTS_START";
export const GET_PDLREPORTS_FAIL = "GET_PDLREPORTS_FAIL";
export const GET_FAILED_PDLREPORT = "GET_FAILED_PDLREPORT";

export const getPDLReportsSuccess = (object) => {
  return {
    type: GET_PDLREPORTS_SUCCESS,
    payload: object,
  };
};

export const getOnePDLReportsSuccess = (report) => {
  return {
    type: GET_ONE_PDLREPORTS_SUCCESS,
    payload: report,
  };
};

export const getFailedReport = (report) => {
  return {
    type: GET_FAILED_PDLREPORT,
    payload: report,
  };
};

export const getPDLReportsStart = () => {
  return {
    type: GET_PDLREPORTS_START,
  };
};
export const getPDLReportsFail = (error) => {
  return {
    type: GET_PDLREPORTS_FAIL,
    payload: error,
  };
};

export const initReport = (object) => {
  return {
    type: GET_ONE_PDLREPORTS_SUCCESS,
    payload: object,
  };
};

export const getAllPDLReports = () => {
  return (dispatch) => {
    dispatch(getPDLReportsStart());
    axios
      .get(`/bdf/pdl-reports`)
      .then((response) => {
        dispatch(getPDLReportsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getPDLReportsFail(err));
      });
  };
};

export const getPDLReportsByDate = (obj) => {
  const ddt = new Date();
  return (dispatch) => {
    dispatch(getPDLReportsStart());
    axios
      .post(`/bdf/pdl-reports/by-date`, obj)
      .then((response) => {
        dispatch(getPDLReportsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getPDLReportsFail(err));
      });
  };
};

export const createPDLReports = (object, token) => {
  return (dispatch) => {
    dispatch(getPDLReportsStart());
    axios
      .post(`/bdf/pdl-reports/create`, object, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOnePDLReportsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getPDLReportsFail(err));
      });
  };
};

export const getBranchPDLReports = (branch, token) => {
  return (dispatch) => {
    dispatch(getPDLReportsStart());
    axios
      .get(`/bdf/pdl-reports/branch/${branch}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOnePDLReportsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getPDLReportsFail(err));
      });
  };
};
