import * as actions from "../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import {
  InputDate,
  InputDate2,
  InputText,
  InputText2,
  InputTextArea,
  InputTextSecret,
  SubmitButton,
} from "../components/inputs";
import Header from "../components/header";
import AdminHeader from "../components/adminheader";
import {
  Beadcrumb,
  ObjectDetails,
  PageTitle,
  formatDecimals,
} from "../components/utils";
import { ReactNotifications } from "react-notifications-component";
import { displayName } from "react-quill";
import {
  errorNotification,
  successNotification,
} from "../components/notifications";
import { isTrue } from "../components/constants";

export const PDLReports = function (props) {
  const dispatch = useDispatch();
  const [username, setusername] = useState("");
  const [password, setpassword] = useState("");
  const user = useSelector((state) => state.auth.user);
  const branch = useSelector((state) => state.auth.branch);
  const token = useSelector((state) => state.auth.token);
  const displayName = useSelector((state) => state.auth.displayName);
  const error = useSelector((state) => state.auth.error);
  const pdlreport = useSelector((state) => state.pdlreport.pdlreport);
  const pdlreportsFail = useSelector((state) => state.pdlreport.pdlreportsFail);
  const WORKSPACE = "PDL REPORTS";
  const [clicked, setclicked] = useState();
  const [submitted, setsubmitted] = useState(false);
  const [totalcandidates, settotalcandidates] = useState("");
  const [reportingdate, setreportingdate] = useState();
  const [totalparticipants, settotalparticipants] = useState("");
  const [amtcollected, settamtcollected] = useState("");
  const [comments, setcomments] = useState("");
  const [thedate, setthedate] = useState();
  const [startingdate, setstartingdate] = useState();

  onsubmit = (e) => {
    e.preventDefault();
    setsubmitted(isTrue);
    if (
      reportingdate === "" ||
      thedate?.getDay() !== 6 ||
      totalcandidates?.trim() === "" ||
      totalparticipants?.trim() === "" ||
      Number(totalparticipants) > Number(totalcandidates)
    ) {
      setclicked(true);
    } else {
      if (window.confirm("Are you sure you want submit this report?")) {
        const obj = {
          submittedby: displayName,
          reportingdate,
          branchName: branch,
          totalcandidates,
          totalparticipants,
          amtcollected,
          comments,
        };
        dispatch(actions.createPDLReports(obj, token));
      } else {
      }
    }
  };

  const history = useHistory();
  useEffect(() => {
    if (pdlreportsFail !== null && pdlreport?.uuid === null) {
      errorNotification("PDL Report");
    }
    if (user !== null) {
      if (user?.roles?.split(",").includes("IT_ADMIN")) {
        history.push("/police-reports");
      } else if (
        user?.roles?.split(",").includes("ANALYST") ||
        user?.roles?.split(",").includes("BRANCH_MANAGER")
      ) {
        history.push("/periodic-reports");
      }
    }
    settamtcollected(totalparticipants * 1000);
    if (pdlreport?.uuid !== undefined && pdlreport?.uuid !== null) {
      successNotification("Report");
    }
    console.log("summitted: ", submitted);
    console.log(user);
  }, [user, totalparticipants, pdlreport, pdlreportsFail]);
  return (
    <>
      <AdminHeader user={user} />
      <ReactNotifications />
      <main className="container" id="main">
        <Beadcrumb />
        <div className="pt-5">
          <PageTitle pagetitle={WORKSPACE} />
        </div>
        <div className="" data-aos="fade-up">
          <div className="row d-flex php-email-form">
            <div className="col-md-12">
              <div className="row">
                <div className="col-sm-10 col-md-6">
                  <div className="row">
                    <div className="col-md-10 pt-5">
                      <div className="form-group py-1">
                        <InputDate2
                          title={"Starting Date (Monday)"}
                          inptype="number"
                          value={startingdate}
                          name="startingdate"
                          placeholder={"Starting Date"}
                          isClicked={clicked}
                          onChange={(e) => {
                            setstartingdate(e);
                            setclicked(false);
                          }}
                        />
                      </div>
                      <div className="form-group py-1">
                        <InputDate2
                          title={"Ending Date (must be Saturday)"}
                          inptype="number"
                          reporttype="pdl"
                          value={reportingdate}
                          name="reportingdate"
                          placeholder={"Reporting Date"}
                          isClicked={clicked}
                          onChange={(e) => {
                            setreportingdate(e);
                            setthedate(new Date(e));
                            setclicked(false);
                          }}
                        />
                      </div>
                      <div className="form-group py-1">
                        <InputText2
                          title={"Total Candidates"}
                          inptype="number"
                          value={totalcandidates}
                          name="totalcandidates"
                          placeholder={"Total Candidates"}
                          isClicked={clicked}
                          onChange={(e) => {
                            settotalcandidates(e.target.value);
                            setclicked(false);
                          }}
                        />
                      </div>
                      <div className="form-group py-1">
                        <InputText2
                          title={"Total Participants"}
                          inptype="number"
                          inpvalue="comparable"
                          value={totalparticipants}
                          name="totalparticipants"
                          placeholder={"Total Participants"}
                          isClicked={clicked}
                          greater={totalcandidates}
                          onChange={(e) => {
                            settotalparticipants(e.target.value);
                            setclicked(false);
                          }}
                        />
                      </div>
                      <div className="form-group py-1">
                        <InputTextArea
                          title={"Comments"}
                          inptype="number"
                          value={comments}
                          name="comments"
                          placeholder={"Total Candidates"}
                          isClicked={clicked}
                          onChange={(e) => {
                            setcomments(e.target.value);
                            setclicked(false);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-10 col-md-6 pt-5">
                  SUMMARY
                  <div className="form-group py-">
                    <ObjectDetails
                      title="Reporting Date"
                      value={moment(reportingdate).format("DD-MM-YYYY")}
                    />
                    <ObjectDetails
                      title="Total Candidates"
                      value={totalcandidates}
                    />
                    <ObjectDetails
                      title="Total Participants"
                      value={totalparticipants}
                    />
                    <ObjectDetails
                      title="Amount Collected"
                      value={formatDecimals(amtcollected + "")}
                    />
                    <ObjectDetails title="Comments" value={comments} />
                  </div>
                </div>
              </div>
              {/*   <div className="row d-flex">
            {objectsCriteriaLoading ? (
              <>
                <div className="w-100 text-center">
                  <MiSpinner />
                </div>
              </>
            ) : (
              <>
                {objectsCriteria?.map((object, index) => (
                  <div
                    className={
                      object?.haschildren === "true"
                        ? "col-sm-10 col-md-12"
                        : "col-sm-10 col-md-6"
                    }
                    key={index}
                  >
                    <div className="form-group py-1">
                      <SelectInputCriteria2nd
                        ref={addToRefs}
                        key={index}
                        id={index}
                        title={object?.criteria}
                        haschildren={object?.haschildren}
                        startingValue={"HITAMO/SELECT"}
                        options={object?.criteriaValues}
                        value={valuess[index + 1]}
                        childvalue={childvalues[index + 1]}
                        changed={()=>{}}
                        childchanged={()=>{}}
                        criteriaId={object?.id}
                        criteria={object?.criteria}
                        isClicked={clicked}
                        subcriteria={[]}
                        subcriteriass={object}
                        subcriteriassi={
                          object.criteriaValues[index]?.subCriteriaValues
                        }
                        criteriaTitle={thecriteriaTitle}
                      />
                    </div>
                  </div>
                ))}
              </>
            )}
          </div> */}
              <div className="pt-3">
                <div className="row gx-5"></div>
                <SubmitButton
                  classes="bg-success"
                  action={"Submit"}
                  onSubmit={onsubmit}
                />
              </div>
            </div>
            <div className="col-md-3 d-flex gy-5 ml-5 justify-content-end"></div>
          </div>
        </div>
      </main>
    </>
  );
};
