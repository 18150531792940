export const isTrue = "true";
export const isFalse = "false";
export const active = "active";
export const inactive = "inactive";
export const individual = "individual";
export const company = "company";
export const cooperative = "cooperative";

export const languages = [
  { name: "Kinyarwanda", value: "rw" },
  { name: "English", value: "en" },
];

export const applicanttypes = [
  { name: "Individual/Umuntu ku giti cye", value: "individual" },
  { name: "Company", value: "company" },
  { name: "Cooperative", value: "cooperative" },
];

export const searchkeys = [
  { name: "Application Code", value: "code" },
  { name: "National Id/Passport", value: "nid" },
  { name: "TIN/RCA Number", value: "tin" },
  { name: "Telephone", value: "telephone" },
];

export const identitiestype = [
  { name: "National ID/Indangamuntu", value: "id" },
  { name: "Passport/Pasiporo", value: "passport" },
];

export const genders = [
  { name: "Gabo/Male", value: "male" },
  { name: "Gore/Female", value: "female" },
];

export const agegroup = [
  { name: "Youth", value: "youth" },
  { name: "Adults", value: "adults" },
];

export const gendersandapptypes = [
  { name: "Male", value: "male" },
  { name: "Female", value: "female" },
  { name: "Individual", value: "individual" },
  { name: "Company", value: "company" },
  { name: "Cooperative", value: "cooperative" },
];

export const criteriaExamples = [
  {
    criteria: "Abakora umushinga/Business Runners",
    criteriaValues: [
      { name: "Urubyiruko/Youth(18-30)", uuid: "Youth uuid" },
      { name: "Women", uuid: "Women uuid" },
      { name: "Other", uuid: "other uuid" },
    ],
  },
  {
    criteria: "Ubwoko bw'umushinga/Business Type",
    criteriaValues: [
      {
        name: "Ubuhinzi n'ubworozi/Agricultural Value Chain",
        uuid: "Yes uuid",
      },
      { name: "Ibindi/ Other Business", uuid: "No uuid" },
    ],
  },
  {
    criteria: "Nyir'umushinga/Business Oner",
    criteriaValues: [
      { name: "Cooperative", uuid: "Cooperative uuid" },
      { name: "Company", uuid: "Company uuid" },
      { name: "Individual", uuid: "Individual uuid" },
    ],
  },
];

const containsOnlyNumbers = (str) => {
  return /^\d+$/.test(str);
};

const lessAndGreater = (little, greaterN) => {
  const n1= Number(little);
  const n2= Number(greaterN);
  if (n1>=n2) {
    console.log("F");
    return false;
  } else {
    console.log("T");
    return true;
  }
};

const equalToZero = (ti) => {
  const half = ti / 2;
  if (ti <= 0) {
    return false;
  } else {
    return true;
  }
};

const greaterTo100M = (ownC) => {
  if (ownC > 100000000) {
    return false;
  } else {
    return true;
  }
};

const validateEmail = (email) => {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
    return true;
  }
  //  alert("You have entered an invalid email address!");
  return false;
};

const validateRCANo = (rcano) => {
  if (/^RCA\/([0-9]{4})\/([0-9]{4}$)/.test(rcano)) {
    return true;
  }
  return false;
};

export default {
  isFalse,
  isTrue,
  languages,
  genders,
  gendersandapptypes,
  agegroup,
  containsOnlyNumbers,
  validateEmail,
  greaterTo100M,
  criteriaExamples,
  active,
  inactive,
  applicanttypes,
  individual,
  company,
  cooperative,
  identitiestype,
  validateRCANo,
  lessAndGreater,
  searchkeys,
  equalToZero,
};
