import { combineReducers } from "redux";
import periodicReducer from "../reducers/periodreport.js";
import authReducer from "../reducers/auth.js";
import pdlperiodicReducer from "../reducers/pdlreport.js";


export default combineReducers({
  report: periodicReducer,
  auth: authReducer,
  pdlreport: pdlperiodicReducer
});
