import axios from "../../axios-base";
import { successNotification } from "../../components/notifications";

export const GET_REPORTS_SUCCESS = "GET_REPORTS_SUCCESS";
export const GET_ONE_REPORTS_SUCCESS = "GET_ONE_REPORTS_SUCCESS";
export const GET_REPORTS_START = "GET_REPORTS_START";
export const GET_REPORTS_FAIL = "GET_REPORTS_FAIL";
export const GET_FAILED_REPORT = "GET_FAILED_REPORT";

export const getReportsSuccess = (reports) => {
  return {
    type: GET_REPORTS_SUCCESS,
    payload: reports,
  };
};

export const getOneReportsSuccess = (report) => {
  return {
    type: GET_ONE_REPORTS_SUCCESS,
    payload: report,
  };
};

export const getFailedReport = (report) => {
  return {
    type: GET_FAILED_REPORT,
    payload: report,
  };
};

export const getReportsStart = () => {
  return {
    type: GET_REPORTS_START,
  };
};
export const getReportsFail = (error) => {
  return {
    type: GET_REPORTS_FAIL,
    payload: error,
  };
};

export const initReport = (object) => {
  return {
    type: GET_ONE_REPORTS_SUCCESS,
    payload: object,
  };
};

export const getReports = () => {
  return (dispatch) => {
    dispatch(getReportsStart());
    axios
      .get(`/bdf/reports`)
      .then((response) => {
        dispatch(getReportsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getReportsFail(err));
      });
  };
};

export const getOneReports = (id) => {
  return (dispatch) => {
    dispatch(getReportsStart());
    axios
      .get(`/bdf/reports/single/${id}`)
      .then((response) => {
        dispatch(getOneReportsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getReportsFail(err));
      });
  };
};

export const getAllBranchReports = (branch)=>{
  return (dispatch) => {
    dispatch(getReportsStart());
    axios
      .get(`/bdf/reports/all-branch-reports/${branch}`)
      .then((response) => {
        dispatch(getReportsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getReportsFail(err));
      });
  };
}

export const getBranchReports = (obj) => {
  return (dispatch) => {
    dispatch(getReportsStart());
    axios
      .post(`/bdf/reports/branch-report`, obj)
      .then((response) => {
        dispatch(getReportsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getReportsFail(err));
      });
  };
};

export const getDailyReports = () => {
  return (dispatch) => {
    dispatch(getReportsStart());
    axios
      .get(`/bdf/reports/daily`)
      .then((response) => {
        dispatch(getOneReportsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getReportsFail(err));
      });
  };
};

export const getBranchDailyReports = (branch) => {
  return (dispatch) => {
    dispatch(getReportsStart());
    axios
      .get(`/bdf/reports/daily/${branch}`)
      .then((response) => {
        dispatch(getOneReportsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getReportsFail(err));
      });
  };
};

export const getAllDataReports = () => {
  return (dispatch) => {
    dispatch(getReportsStart());
    axios
      .get(`/bdf/reports`)
      .then((response) => {
        dispatch(getOneReportsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getReportsFail(err));
      });
  };
};

export const getWeeklyReports = () => {
  return (dispatch) => {
    dispatch(getReportsStart());
    axios
      .get(`/bdf/reports/weekly`)
      .then((response) => {
        dispatch(getOneReportsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getReportsFail(err));
      });
  };
};

export const getMonthlyReports = () => {
  return (dispatch) => {
    dispatch(getReportsStart());
    axios
      .get(`/bdf/reports/monthly`)
      .then((response) => {
        dispatch(getOneReportsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getReportsFail(err));
      });
  };
};

export const getDailyReportsByDate = (date) => {
  return (dispatch) => {
    dispatch(getReportsStart());
    axios
      .get(`/bdf/reports/daily/date/${date}`)
      .then((response) => {
        dispatch(getOneReportsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getReportsFail(err));
      });
  };
};

export const getBranchDailyReportsByDate = (date, branch) => {
  return (dispatch) => {
    dispatch(getReportsStart());
    axios
      .get(`/bdf/reports/daily/date/${date}/branch/${branch}`)
      .then((response) => {
        dispatch(getOneReportsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getReportsFail(err));
      });
  };
};

export const getWeeklyReportsByDate = (date) => {
  return (dispatch) => {
    dispatch(getReportsStart());
    axios
      .get(`/bdf/reports/weekly/date/${date}`)
      .then((response) => {
        dispatch(getOneReportsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getReportsFail(err));
      });
  };
};

export const getBranchWeeklyReportsByDate = (date, branch) => {
  return (dispatch) => {
    dispatch(getReportsStart());
    axios
      .get(`/bdf/reports/weekly/date/${date}/branch/${branch}`)
      .then((response) => {
        dispatch(getOneReportsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getReportsFail(err));
      });
  };
};

export const findBranchDailyReportsForWeekly = (date, branch) => {
  return (dispatch) => {
    dispatch(getReportsStart());
    axios
      .get(`/bdf/reports/daily-to-weekly/date/${date}/branch/${branch}`)
      .then((response) => {
        dispatch(getOneReportsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getReportsFail(err));
      });
  };
};

export const getMonthlyReportsByDate = (date) => {
  return (dispatch) => {
    dispatch(getReportsStart());
    axios
      .get(`/bdf/reports/monthly/date/${date}`, date)
      .then((response) => {
        dispatch(getOneReportsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getReportsFail(err));
      });
  };
};

export const getBranchMonthlyReportsByDate = (date, branch) => {
  return (dispatch) => {
    dispatch(getReportsStart());
    axios
      .get(`/bdf/reports/monthly/date/${date}/branch/${branch}`, date)
      .then((response) => {
        dispatch(getOneReportsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getReportsFail(err));
      });
  };
};

export const getReportById = (id) => {
  return (dispatch) => {
    dispatch(getReportsStart());
    axios
      .get(`/bdf/reports/single/${id}`)
      .then((response) => {
        dispatch(getOneReportsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getReportsFail(err));
      });
  };
};

export const submitReports = (object) => {
  console.log("object Rpt: ");
  return (dispatch) => {
    dispatch(getReportsStart());
    axios
      .post(`/bdf/reports/submit`, object)
      .then((response) => {
        dispatch(getOneReportsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getReportsFail(err.message));
      });
  };
};

export const editReports = (object) => {
  return (dispatch) => {
    dispatch(getReportsStart());
    axios
      .post(`/bdf/reports/edit`, object)
      .then((response) => {
        dispatch(getOneReportsSuccess(response.data.results));
        successNotification("Report Updated Successfully");
      })
      .catch((err) => {
        dispatch(getReportsFail(err.message));
      });
  };
};
