import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import * as XLSX from "xlsx/xlsx.mjs";
import {
  ActivateButton,
  EditButton,
  InputDate,
  InputDate2,
  InputDate2Search,
  InputText,
  InputText2,
  InputTextArea,
  InputTextSecret,
  MiniTitle,
  PageLink,
  SelectInput,
  SubmitButton,
  ViewButton,
} from "../components/inputs";
import Header from "../components/header";
import AdminHeader from "../components/adminheader";
import MiSpinner, {
  Beadcrumb,
  NotReportingBranches,
  ObjectDetails,
  PageTitle,
  formatDecimals,
} from "../components/utils";
import { ReactNotifications } from "react-notifications-component";
import { displayName } from "react-quill";
import {
  errorNotification,
  successNotification,
} from "../components/notifications";
import { isTrue } from "../components/constants";
import {
  getAllPDLReports,
  getBranchPDLReports,
  getPDLReportsByDate,
} from "../store/actions/pdlreport";
import { getOneReports, getReports } from "../store/actions";
import Modal from "react-bootstrap/Modal";
import { getAllBranchReports, getBranchReports } from "../store/actions/periodreport";

export const PeriodicReportsList = function (props) {
  const dispatch = useDispatch();
  const [username, setusername] = useState("");
  const [password, setpassword] = useState("");
  const user = useSelector((state) => state.auth.user);
  const branch = useSelector((state) => state.auth.branch);
  const token = useSelector((state) => state.auth.token);
  const error = useSelector((state) => state.auth.error);
  const object = useSelector((state) => state.pdlreport.pdlreport);
  const objectList = useSelector((state) => state.pdlreport.pdlreport);
  const loading = useSelector((state) => state.pdlreport.pdlreportsLoading);
  const pdlreportsFail = useSelector((state) => state.pdlreport.pdlreportsFail);
  const WORKSPACE = "PDL REPORTS LIST";
  const [clicked, setclicked] = useState();
  const [submitted, setsubmitted] = useState(false);
  const [totalcandidates, settotalcandidates] = useState("");
  const [reportingdate, setreportingdate] = useState();
  const [totalparticipants, settotalparticipants] = useState("");
  const [amtcollected, settamtcollected] = useState("");
  const [comments, setcomments] = useState("");

  const history = useHistory();
  useEffect(() => {
    dispatch(getBranchPDLReports(branch, token));
  }, []);
  return (
    <>
      <AdminHeader user={user} />
      <ReactNotifications />
      <main className="container" id="main">
        <Beadcrumb />
        <div className="pt-5">
          <PageTitle pagetitle={WORKSPACE} />
        </div>
        <div className="" data-aos="fade-up">
          <div className="pl-5 pb-3">
            <ObjectDetails title="Branch" value={branch} />
          </div>
          <div className="row d-flex php-email-form">
            <div className="col-md-12">
              <table className="table table-striped text-center">
                <thead>
                  <tr className="bdf-bluefontcolor">
                    <th>#</th>
                    <th>Date</th>
                    <th>Candidates</th>
                    <th>Partcipants</th>
                    <th>Amount</th>
                    <th>Reported By</th>
                    <th className="text-center d-none">Action</th>
                  </tr>
                </thead>
                <tbody className="">
                  {objectList?.listOfReports?.map((data, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>
                        {moment(data?.reportingdate).format("DD-MM-YYYY")}
                      </td>
                      <td>{data?.totalcandidates}</td>
                      <td>{data?.totalparticipants}</td>
                      <td>{formatDecimals(data?.amtcollected + "")}</td>
                      <td>{data?.submittedby}</td>
                      <td className="row d-flex justify-content-center d-none">
                        <EditButton
                          title="Edit"
                          page="/admin/about/bdf-background"
                          id={data?.uuid}
                        />
                        <span className="px-2 w-auto"></span>
                        <ViewButton
                          title={"View "}
                          onClick={(e) => {
                            e.preventDefault();
                            // viewMore(data?.uuid);
                          }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="col-md-3 d-flex gy-5 ml-5 justify-content-end"></div>
          </div>
        </div>
      </main>
    </>
  );
};

export const AllPeriodicReportsList = function (props) {
  const dispatch = useDispatch();
  const [username, setusername] = useState("");
  const [password, setpassword] = useState("");
  const user = useSelector((state) => state.auth.user);
  const branch = useSelector((state) => state.auth.branch);
  const token = useSelector((state) => state.auth.token);
  const error = useSelector((state) => state.auth.error);
  const object = useSelector((state) => state.report?.report);
  const objectList = useSelector((state) => state.report?.reports);
  const loading = useSelector((state) => state.pdlreport.pdlreportsLoading);
  const pdlreportsFail = useSelector((state) => state.pdlreport.pdlreportsFail);
  const WORKSPACE = "PERIODIC REPORTS LIST";
  const [clicked, setclicked] = useState();
  const [submitted, setsubmitted] = useState(false);
  const [totalcandidates, settotalcandidates] = useState("");
  const [reportingdate, setreportingdate] = useState();
  const [show, setShow] = useState(false);
  const [periodtype, setperiodtype] = useState("");
  const periods = [
    { id: 1, value: "Daily" },
    { id: 2, value: "Weekly" },
    { id: 3, value: "Monthly" },
  ];
  let semiarray1 = [];
  let semiarray2 = [];

  if (objectList?.notReportedBranch?.length > 2) {
    let mid = objectList?.notReportedBranch.length / 2;
    for (let i = 0; i < mid; i++) {
      semiarray1.push(objectList?.notReportedBranch[i]);
    }
    for (let i = objectList?.notReportedBranch?.length - 1; i >= mid; i--) {
      semiarray2.push(objectList?.notReportedBranch[i]);
    }
  } else {
    for (let i = 0; i < objectList?.notReportedBranch?.length; i++) {
      semiarray1.push(objectList?.notReportedBranch[i]);
    }
  }

  const exportToExcel = () => {
    let wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(objectList?.listOfReports);
    XLSX.utils.book_append_sheet(wb, ws, "Periodic");
    XLSX.writeFile(wb, "Periodic Report.xlsx");
  };

  const handleClose = (e) => {
    e.preventDefault();
    setShow(false);
    dispatch(getReports());
  };

  const handleShow = (e) => {
    // e.preventDefault();
    dispatch(getOneReports(e));
    setShow(true);
    if (object !== null) {
    }
  };

  const history = useHistory();
  useEffect(() => {
    if (reportingdate !== undefined && reportingdate !== null) {
      const obj = {
        date: reportingdate,
        branch,
        periodtype
      };
      if (periodtype !== undefined && periodtype !== null) {
        dispatch(getBranchReports(obj));
      } else {
        alert("Select Period");
      }
  
    } else {
      if(branch !== "HQ"){
        dispatch(getAllBranchReports(branch));
      }else{
        dispatch(getReports());
      }
      
    }
  }, [reportingdate]);
  return (
    <>
      <AdminHeader user={user} />
      <ReactNotifications />
      <main className="container pb-5" id="main">
        <Modal show={show} onHide={handleClose} size="xl">
          <Modal.Body>
            <div className="row px-5 pt-3 pb-3">
              <h6 className="text-primary text-center pb-3">
                {object?.branchName ?? ""}
                {" " +
                  (object?.period ?? "") +
                  " Report Dated " +
                  moment(object?.ReportingDate).format("DD-MM-YYYY")}
              </h6>
              <div className="col-sm-12 col-md-4">
                <MiniTitle title="Guarantee" />
                <ObjectDetails
                  title={"Guarantee Reviewed"}
                  value={object?.guaranteeReviewed}
                />
                <ObjectDetails
                  title={"Guarantee Analyzed"}
                  value={object?.guaranteeAnalyzed}
                />
                <ObjectDetails
                  title={"Guarantee Approved"}
                  value={object?.guaranteeApproved}
                />
                <ObjectDetails
                  title={"Commissions"}
                  value={object?.guaranteeCommissions}
                />

                <MiniTitle title="ERF" />
                <ObjectDetails
                  title={"ERF Reviewed"}
                  value={object?.erfReviewed}
                />
                <ObjectDetails
                  title={"ERF Analyzed"}
                  value={object?.erfAnalyzed}
                />
                <ObjectDetails
                  title={"ERF Approved"}
                  value={object?.erfApproved}
                />

                <MiniTitle title="Agri-business" />
                <ObjectDetails
                  title={"Agri-business Reviewed"}
                  value={object?.agribusinessReviewed}
                />
                <ObjectDetails
                  title={"Agri-business Analyzed"}
                  value={object?.agribusinessAnalyzed}
                />
                <ObjectDetails
                  title={"Agri-business Approved"}
                  value={object?.agribusinessApproved}
                />
              </div>
              <div className="col-sm-12 col-md-4">
                <MiniTitle title="Leasing" />
                <ObjectDetails
                  title={"Leasing Reviewed"}
                  value={object?.leasingReviewed}
                />
                <ObjectDetails
                  title={"Leasing Analyzed"}
                  value={object?.leasingAnalyzed}
                />
                <ObjectDetails
                  title={"Leasing Approved"}
                  value={object?.leasingApproved}
                />
                <MiniTitle title="Micro Leasing" />
                <ObjectDetails
                  title={"Micro Leasing Reviewed"}
                  value={object?.microLeasingReviewed}
                />
                <ObjectDetails
                  title={"Micro Leasing Analyzed"}
                  value={object?.microLeasingAnalyzed}
                />
                <ObjectDetails
                  title={"Micro Leasing Approved"}
                  value={object?.microLeasingApproved}
                />
                <MiniTitle title="Others" />
                <ObjectDetails title={"Claims"} value={object?.claims} />
                <ObjectDetails
                  title={"Drafted Notifications"}
                  value={object?.draftedNotifications}
                />
                <ObjectDetails
                  title={"Drafted Contracts"}
                  value={object?.draftedContracts}
                />
                <ObjectDetails
                  title={"Projects Monitorored"}
                  value={object?.projectsMonitored}
                />
              </div>
              <div className="col-sm-12 col-md-4">
                <MiniTitle title="Sacco Refinancing Recoveries" />
                <ObjectDetails
                  title={"Normal "}
                  value={object?.saccoNormalRecoveries}
                />
                <ObjectDetails
                  title={"Arrears"}
                  value={object?.saccoArrearsRecoveries}
                />
                <MiniTitle title="Agri-business Recoveries" />
                <ObjectDetails
                  title={"Normal "}
                  value={object?.agribusinessNormalRecoveries}
                />
                <ObjectDetails
                  title={"Arrears"}
                  value={object?.agribusinessArrearsRecoveries}
                />
                <MiniTitle title="Leasing Recoveries" />
                <ObjectDetails
                  title={"Normal "}
                  value={object?.leasingNormalRecoveries}
                />
                <ObjectDetails
                  title={"Arrears"}
                  value={object?.leasingArrearsRecoveries}
                />
                <MiniTitle title="Micro Leasing Recoveries" />
                <ObjectDetails
                  title={"Normal "}
                  value={object?.microLeasingNormalRecoveries}
                />
                <ObjectDetails
                  title={"Arrears"}
                  value={object?.microLeasingArrearsRecoveries}
                />
              </div>
            </div>
            <div className="row px-5 pt-3 pb-5">
              <div className="col-sm-12 col-md-3">
                <MiniTitle title="Disbursements" />
                <ObjectDetails
                  title={"RDDP"}
                  value={object?.rddpDisbursement}
                />
                <ObjectDetails
                  title={"Other"}
                  value={object?.otherDisbursement}
                />
              </div>
              <div className="col-sm-12 col-md-3">
                <MiniTitle title="Revenues" />
                <ObjectDetails
                  title={"IT & Services"}
                  value={object?.itAndOtherServicesRevenues}
                />
              </div>
              <div className="col-sm-12 col-md-6">
                <MiniTitle title="Comments" />
                <ObjectDetails title={"Comments"} value={object?.comments} />
              </div>
            </div>
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-primary text-white"
                onClick={handleClose}
              >
                Close
              </button>{" "}
            </div>
          </Modal.Body>
        </Modal>
        <Beadcrumb />
        <div className="pt-5">
          <PageTitle pagetitle={WORKSPACE} />
        </div>
        <div className="" data-aos="fade-up">
          <div className="pl-5 pb-3">
            <ObjectDetails title="Office" value={branch} />
          </div>
          <div className="row">
            <div className="form-group  col-md-3">
              <SelectInput
                title="Period"
                startingValue="Select Period"
                options={periods}
                value={periodtype}
                changed={setperiodtype}
                isClicked={clicked}
              />
            </div>
            <div className="form-group py-3 col-md-3">
              <InputDate2Search
                title={"Date"}
                inptype="number"
                value={reportingdate}
                name="reportingdate"
                placeholder={"Reporting Date"}
                isClicked={clicked}
                onChange={(e) => {
                  setreportingdate(e);
                  setclicked(false);
                }}
              />
            </div>
          </div>
          <div className="pt-3">
            <SubmitButton
              classes="bg-success"
              action={"Get Excel"}
              onSubmit={exportToExcel}
            />
          </div>
          <div className="row d-flex php-email-form">
            <div className="col-md-12">
              <table className="table table-striped">
                <thead>
                  <tr className="bdf-bluefontcolor">
                    <th>#</th>
                    <th>Date</th>
                    <th>Branch</th>
                    <th>Period</th>
                    <th>Comments</th>
                    <th>Reported By</th>
                    <th className="text-center">Action</th>
                  </tr>
                </thead>
                <tbody className="">
                  {objectList?.listOfReports?.map((data, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>
                        {moment(data?.ReportingDate).format("DD-MM-YYYY")}
                      </td>
                      <td>{data?.branchName}</td>
                      <td>{data?.period}</td>
                      <td>{data?.comments}</td>
                      <td>{data?.submittedBy}</td>
                      <td className="row d-flex justify-content-center">
                        <ViewButton
                          title={"View "}
                          onClick={(e) => {
                            e.preventDefault();
                            handleShow(data?.uuid);
                          }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="col-md-3 d-flex gy-5 ml-5 justify-content-end"></div>
          </div>
        </div>
      </main>
    </>
  );
};
