import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";

export const Beadcrumb = () => {
  return <div className="breadcrumbs-modified d-flex align-items-center"></div>;
};

function MiSpinner(props) {
  return (
    <div className="text-center align-middle mi-spinner">
      <Spinner animation="border" variant="primary" size="xl" />
    </div>
  );
}

export default MiSpinner;

export const ObjectDetails = function (props) {
  return (
    <div className="pb-1">
      <span className="text-primary">{props?.title}:</span>
      <span className={props?.value === undefined ? "" : ""}>
        {props?.value === undefined ? " 0" : "  " + props?.value}
      </span>
    </div> 
  );
};

export const ObjectDetailsNumber = function (props) {
  return (
    <div className="pb-1">
      <span className="text-primary">{props?.title}:</span>
      <span className={props?.value === undefined ? "" : ""}>
        {props?.value === undefined ? " 0" : "  " + props?.value + " "+ formatDecimals(props?.value)}
      </span>
    </div> 
  );
};

export const CountDown = () => {
  const history = useHistory();
  const [days, setdays] = useState(0);
  const [hours, sethours] = useState(0);
  const [minutes, setminutes] = useState(0);
  const [seconds, setseconds] = useState(0);
  const dispatch = useDispatch();
  const deadline = useSelector(
    (state) => state.periods?.activePeriod?.closingdate
  );
  const activeperiod = useSelector((state) => state.periods?.activePeriod);
  let tocome = new Date(deadline).getTime();
  var x = setInterval(() => {
    let now = new Date().getTime();
    let diff = tocome - now;
    setdays(Math.floor(diff / (1000 * 60 * 60 * 24)));
    sethours(Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)));
    setminutes(Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60)));
    setseconds(Math.floor((diff % (1000 * 60)) / 1000));
  }, 1000);

  useEffect(() => {
    if (new Date(deadline).getTime() - new Date().getTime() < 0) {
      history.push("/");
    }
  }, []);

  return (
    <div className="d-flex justify-content-end pt-2">
      {new Date(deadline).getTime() - new Date().getTime() > 0 ? (
        <marquee>
          <div className="text-primary">
            Igihe cyo gusaba nikirangira, gusaba bizahagarara. Iminsi isigaye:
            {days}, amasaha: {hours}:{minutes}:{seconds}.
          </div>
          <div>
            When application period ends, application will close. Remaining
            days:{days}, time: {hours}:{minutes}:{seconds}.
          </div>{" "}
        </marquee>
      ) : (
        <marquee className="text-danger">
          Igihe cyo kwakira ibitekerezo by'imishinga cyararangiye
          <div className="text-danger">
            The application period has ended
          </div>{" "}
        </marquee>
      )}
    </div>
  );
};

export const PageTitle = (props) => {
  return (
    <h5 className="bdf-bluefontcolor-title text-center pt-3">{props?.pagetitle}</h5>
  );
};

export const RequiredStar = () => {
  return <span className="text-danger required-star"> * </span>;
};

export const ResultCard = () => {
  <div className={"alert alert-info text-center pt-3"} role="alert">
    Ntabwo mwemere inkunga kubera mwabonye / You are not allowed grant because
    you received{" "}
  </div>;
};

export const formatDecimals = (val) => {
  let removeChar = val?.replace(/[^0-9.]/g, "");
  let removeDot = removeChar.replace(/\./g, "");
  let formatedNumber = removeDot.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return formatedNumber;
};

export const NotReportingBranches = function (props) {
  return (
    <span className="ml-3 text-white bg-primary py-1 px-3 not-reporting">{props?.name}</span>
  );
};
