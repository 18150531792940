import "./App.css";
import "./assets/vendor/bbootstrap/css/bootstrap.min.css";
import "./assets/vendor/bootstrap-icons/bootstrap-icons.css";
import "./assets/vendor/glightbox/css/glightbox.min.css";
import "./assets/vendor/swiper/swiper-bundle.min.css";
import "./assets/vendor/remixicon/remixicon.css";
import "./assets/css/main.css";
import { BrowserRouter as Router } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Route, Switch } from "react-router-dom";
import isEmpty from "lodash/isEmpty";
import { store } from "./store/store";
import { authLogout, authSuccess } from "./store/actions/auth";
import jwtDecode from "jwt-decode";
import "react-notifications-component/dist/theme.css";
// import { MiModal } from "./admin/components/MiModal";
import { useSelector } from "react-redux";
//import { AdminLoginPage } from "./admin/login";
import { AdminLoginPage } from "./publicpages/login";
import { PDLReports } from "./pages/pdlreports";
import { AllPDLReportsList, PDLReportsList } from "./pages/pdlreportslist";
import { AllPeriodicReports, PeriodicReports } from "./pages/periodreports";
import { UsersList } from "./pages/users";
import { AllPeriodicReportsList } from "./pages/periodicreportslist";

const token = localStorage.jwt;

(async () => {
  try {
    if (isEmpty(token)) {
      store.dispatch(authLogout());
      return;
    }

    //decode token
    const decodedUser = jwtDecode(token);
    if (decodedUser) {
      store.dispatch(authSuccess({ token: token, user: decodedUser}));
    }
  } catch (err) {
    store.dispatch(authLogout());
    window.location.reload();
    return;
  }
})();

function App() {
  const isAuthenticated = useSelector((state) => state.auth.token !== null);
  let routes = (
    <Switch>
      <Route exact path="/">
        <AdminLoginPage />
      </Route>
      <Route exact path="/sign-in">
        <AdminLoginPage />
      </Route>
    </Switch>
  );
  if (isAuthenticated) {
    routes = (
      <Switch>
        <Route exact path="/">
          <AdminLoginPage />
        </Route>
        <Route exact path="/sign-in">
          <AdminLoginPage />
        </Route>
        <Route exact path="/police-reports">
          <PDLReports />
        </Route>
        <Route exact path="/periodic-reports">
          <PeriodicReports/>
        </Route>
        <Route exact path="/police-reports-list">
          <PDLReportsList />
        </Route>
        <Route exact path="/periodic-reports-list">
          <AllPeriodicReportsList />
        </Route>
        <Route exact path="/all-pdlreports-list">
          <AllPDLReportsList />
        </Route>
        <Route exact path="/all-periodic-reports-list">
          <AllPeriodicReportsList />
        </Route>
        <Route exact path="/users">
          <UsersList />
        </Route>
      </Switch>
    );
  }
  return (
    <>
      <Helmet>
        <title>BDF - Business Development Fund</title>
        <meta name="description" content="BDF description BDF Rwanda" />
        <meta
          name="keywords"
          content="funding, guarantee, leasing, agri business, Access to finance, Access to finance for SMEs, Access to finance for entrepreneurs,
          Access to finance Rwanda, SME, Credit guarantee scheme, Bank guarantee letter, Microloans,
          Small business loans, Business financing, Types of business financing"
        />
        <link rel="icon" href="/logo512.png" />
      </Helmet>
      <Router>{routes}</Router>
    </>
  );
}

export default App;
