import { useEffect } from "react";
import { NavLink } from "react-router-dom";
import logo from "../bdflogoo.png";
import { useTranslation } from "react-i18next";

export const HeaderLink = function (props) {
  const checkActive = (match, location) => {
    if (match) {
      const { pathname } = location;
      const { url } = match;
      return pathname === url || props.indicator === url;
    } else {
      if (props.indicator) return true;
    }
  };
  const totgling = (e) => {
    e.preventDefault();
    document.querySelector("body").classList.remove("mobile-nav-active");
  };
  return (
    <div onClick={totgling}>
      <NavLink
        to={props.page}
        isActive={checkActive}
        activeClassName="active"
        className=""
      >
        {props.name}
      </NavLink>
    </div>
  );
};

export const HomeHeaderLink = function (props) {
  return (
    <NavLink to={"/"} className="logo d-flex align-items-center">
      <img src={logo} alt="" />
    </NavLink>
  );
};

export const ViewMoreButton = function (props) {
  return (
    <NavLink to="/services" className="buttons">
      View More
    </NavLink>
  );
};

export const ViewMoreLink = function (props) {
  const { t } = useTranslation();
  return (
    <span className="viewmore-link" onMouseDown={props.onClick}>
      {t("Read More")}
    </span>
  );
};

export const ApplicationLink = function (props) {
  return (
    <li>
      <i className="bi bi-dash"></i>
      <a target={"_blank"} href={props?.link} className="">
        {props?.name}
      </a>
    </li>
  );
};

export const ApplicationLinkHeader = function (props) {
  return (
    <li>
      <a target={"_blank"} href={props?.link} className="">
        {props?.name}
      </a>
    </li>
  );
};
