import { useEffect, useState } from "react";
import { HeaderLink, HomeHeaderLink } from "../components/links";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../store/actions/auth";
import { useHistory } from "react-router-dom";

const AdminHeader = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const token = useSelector((state) => state.auth.token);
  const displayName = useSelector((state) => state.auth.displayName);
  const roles = useSelector((state) => state.auth.roles);
  const branch = useSelector((state) => state.auth.branch);
  // const roles = localStorage.roles;
  const user = props?.user;
  const logouti = (e) => {
    e.preventDefault();
    dispatch(logout());
    history.push("/sign-in");
  };

  const profile = (e) => {
    e.preventDefault();
    history.push("/admin/profile");
  };

  useEffect(() => {
    //  console.log("ROLES: ", roles);
  }, []);

  return (
    <header
      id="header"
      className="header d-flex align-items-center fixed-top bg-bdf-blue"
    >
      <div className="container-fluid container-xl d-flex align-items-center justify-content-between">
        <HomeHeaderLink />
        <nav id="navbar" className={token ? "navbar" : "d-none"}>
          <ul className="me-3">
            <li
              className={
                roles?.includes("ANALYST") || roles?.includes("BRANCH_MANAGER")
                  ? "dropdown"
                  : "d-none"
              }
            >
              <HeaderLink page="/periodic-reports" name={"Periodic Reports"} />
            </li>
            <li className={roles?.includes("IT_ADMIN") ? "dropdown" : "d-none"}>
              <HeaderLink page="/police-reports" name={"Police Reports"} />
            </li>
            <li
              className={
                roles?.includes("SYS_ADMIN") || roles?.includes("IT_ADMIN")
                  ? "dropdown"
                  : "d-none"
              }
            >
              <HeaderLink
                page="/police-reports-list"
                name={"Submitted Reports"}
              />
              <ul>
                <li
                  className={
                    roles?.includes("ANALYST") || roles?.includes("BRANCH_MANAGER")
                      ? ""
                      : "d-none"
                  }
                >
                  <HeaderLink
                    page="/periodic-reports-list"
                    name={"Periodic Reports"}
                  />
                </li>
                <li
                  className={
                    roles?.includes("IT_ADMIN") 
                      ? ""
                      : "d-none"
                  }
                >
                  {" "}
                  <HeaderLink
                    page="/police-reports-list"
                    name={"Police Reports"}
                  />
                </li>
              </ul>
            </li>
            <li className={roles?.includes("COORDINATOR") || roles?.includes("SYS_ADMIN") ? "dropdown" : "d-none"}>
              <HeaderLink page="" name={"All Reports"} />
              <ul>
                <li
                  className={
                    roles?.includes("SYS_ADMIN") ||
                    roles?.includes("COORDINATOR")
                      ? ""
                      : "d-none"
                  }
                >
                  <HeaderLink
                    page="/all-periodic-reports-list"
                    name={"Periodic Reports"}
                  />
                </li>
                <li
                  className={
                    roles?.includes("SYS_ADMIN") || roles?.includes("IT_MGR")
                      ? ""
                      : "d-none"
                  }
                >
                  {" "}
                  <HeaderLink
                    page="/all-pdlreports-list"
                    name={"Police Reports"}
                  />
                </li>
              </ul>
            </li>
            <li
              className={roles?.includes("SYS_ADMIN") ? "dropdown" : "d-none"}
            >
              <HeaderLink page="/users" name={"Users"} />
            </li>
          </ul>
        </nav>
        <div className={token ? "row user-profile" : "d-none"}>
          <span className="col-md-8">{displayName}</span>
          <i
            onMouseDown={profile}
            className="bi-person-heart col-md-2 d-none"
          ></i>
          <i onMouseDown={logouti} className="bi-lock-fill col-md-2"></i>
        </div>
      </div>
    </header>
  );
};

export default AdminHeader;
