import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import * as XLSX from 'xlsx/xlsx.mjs';
import {
  ActivateButton,
  EditButton,
  InputDate,
  InputDate2,
  InputDate2Search,
  InputText,
  InputText2,
  InputTextArea,
  InputTextSecret,
  PageLink,
  SubmitButton,
  ViewButton,
} from "../components/inputs";
import Header from "../components/header";
import AdminHeader from "../components/adminheader";
import MiSpinner, {
  Beadcrumb,
  NotReportingBranches,
  ObjectDetails,
  PageTitle,
  formatDecimals,
} from "../components/utils";
import { ReactNotifications } from "react-notifications-component";
import { displayName } from "react-quill";
import {
  errorNotification,
  successNotification,
} from "../components/notifications";
import { isTrue } from "../components/constants";
import {
  getAllPDLReports,
  getBranchPDLReports,
  getPDLReportsByDate,
} from "../store/actions/pdlreport";
import { getUsers } from "../store/actions";

export const UsersList = function (props) {
  const dispatch = useDispatch();
  const [username, setusername] = useState("");
  const [password, setpassword] = useState("");
  const user = useSelector((state) => state.auth.user);
  const branch = useSelector((state) => state.auth.branch);
  const token = useSelector((state) => state.auth.token);
  const error = useSelector((state) => state.auth.error);
  const object = useSelector((state) => state.pdlreport.pdlreport);
  const objectList = useSelector((state) => state.auth.users);
  const loading = useSelector((state) => state.pdlreport.pdlreportsLoading);
  const pdlreportsFail = useSelector((state) => state.pdlreport.pdlreportsFail);
  const WORKSPACE = "USERS LIST";
  const [clicked, setclicked] = useState();
  const [submitted, setsubmitted] = useState(false);
  const [totalcandidates, settotalcandidates] = useState("");
  const [reportingdate, setreportingdate] = useState();
  const [totalparticipants, settotalparticipants] = useState("");
  const [amtcollected, settamtcollected] = useState("");
  const [comments, setcomments] = useState("");

  const history = useHistory();
  useEffect(() => {
    dispatch(getUsers(branch, token));
  }, []);
  return (
    <>
      <AdminHeader user={user} />
      <ReactNotifications />
      <main className="container" id="main">
        <Beadcrumb />
        <div className="pt-5">
          <PageTitle pagetitle={WORKSPACE} />
        </div>
        <div className="" data-aos="fade-up">
          <div className="pl-5 pb-3">
            <ObjectDetails title="Office" value={branch} />
          </div>
          <div className="row d-flex php-email-form">
            <div className="col-md-12">
              <table className="table table-striped">
                <thead>
                  <tr className="bdf-bluefontcolor">
                    <th>#</th>
                    <th>Names</th>
                    <th>Branch</th>
                    <th>Email</th>
                    <th>Telephone</th>
                    <th>Permissions</th>
                  </tr>
                </thead>
                <tbody className="">
                  {objectList?.map((data, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{data?.fullName}</td>
                      <td>{data?.branch}</td>
                      <td>{data?.username}</td>
                      <td>{data?.telephone}</td>
                      <td>{data?.role}</td>
                      <td className="row d-flex justify-content-center d-none">
                        <EditButton
                          title="Edit"
                          page="/admin/about/bdf-background"
                          id={data?.uuid}
                        />
                        <span className="px-2 w-auto"></span>
                        <ViewButton
                          title={"View "}
                          onClick={(e) => {
                            e.preventDefault();
                            // viewMore(data?.uuid);
                          }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="col-md-3 d-flex gy-5 ml-5 justify-content-end"></div>
          </div>
        </div>
      </main>
    </>
  );
};