import { updateObject } from "../../utils/utility";
import {
  GET_REPORTS_START,
  GET_REPORTS_SUCCESS,
  GET_ONE_REPORTS_SUCCESS,
  GET_REPORTS_FAIL,
  GET_FAILED_REPORT
} from "../actions/periodreport";

const initialReducer = {
  report: null,
  reports: [],
  reportsLoading: false,
  reportsFail: null,
  failedReport: null,
};

const getReportsStart = (state, action) => {
  return updateObject(state, {
    reports: [],
    report: null,
    reportsLoading: true,
    reportsFail: null,
  });
};

const getReportsSuccess = (state, action) => {
  return updateObject(state, {
    reports: action.payload,
    reportsLoading: false,
    reportsFail: null,
    report: null,
  });
};

const getOneReportsSuccess = (state, action) => {
  return updateObject(state, {
    report: action.payload,
    reports: [],
    reportsLoading: false,
    reportsFail: null,
  });
};

const getReportsFail = (state, action) => {
  return updateObject(state, {
    reports: [],
    report: null,
    reportsLoading: false,
    reportsFail: action.payload,
  });
};

const getFailedReport = (state, action) => {
  return updateObject(state, {
    reports: [],
    report: null,
    reportsLoading: false,
    reportsFail: null,
    failedReport: action.payload,
  });
};

const reducer = (state = initialReducer, action) => {
  switch (action.type) {
    case GET_REPORTS_START:
      return getReportsStart(state, action);
    case GET_REPORTS_SUCCESS:
      return getReportsSuccess(state, action);
    case GET_ONE_REPORTS_SUCCESS:
      return getOneReportsSuccess(state, action);
    case GET_REPORTS_FAIL:
      return getReportsFail(state, action);
    case GET_FAILED_REPORT:
      return getFailedReport(state, action);
    default:
      return state;
  }
};

export default reducer;
